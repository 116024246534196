import React, { useEffect } from 'react';
import { Row, Col } from 'react-materialize';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../asset/css/adminPanel.css';

const AdvPage = props => {
  useEffect(() => {
    props.getAllAdvs();
  }, []);
  
  return (
    <div className="about-us-component">
      <Row>
        <Col m={12} s={12}>
          <table className='admin-panel-table striped'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Earnings</th>
                <th>Mode</th>
                <th>Clicks purchased</th>
                <th>Clicks left</th>
              </tr>
            </thead>
            <tbody>
              {props.allUserAdvs.map((item, i) => 
                <tr key={i}>
                  <td>{item.title}</td>
                  <td>{item.earn}</td>
                  <td>{item.item}</td>
                  <td>{item.price}</td>
                  <td>{item.clicks}</td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    allUserAdvs: state.allUserAdvs || []
  };
}

export default connect(mapStateToProps, actions)(AdvPage);