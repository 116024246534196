// import React, { Component } from 'react';
// import { connect } from 'react-redux';
// import * as actions from '../actions';

// class PayppalPage extends Component {
    
//     render() {
//         return (
//             <div className="about-us-component">
//               <div className="youtube-view">
//                 <iframe title="About us" width="700" height="500" style={{margin: 'auto', display: 'block', marginTop: 20}}
//                   src="https://www.youtube.com/embed/yqWX86uT5jM?&autoplay=1" frameborder="0" allowfullscreen>
//                 </iframe>
//               </div>
//             </div> 
//         )
//     }
// };

// export default connect(null, actions)(PayppalPage);

import React,{useState} from 'react'
import Lang from '../language/lang.js';

const AboutUsPage = () => {
    const [type,setType] = useState('Users')

    return (
        <div>
            <div className="card-action center-align mt-4 mb-4">
                <a onClick={() => setType('Users')} className="waves-effect waves-light btn-large">
                    Users
                </a>
                <a onClick={() => setType('Advertisers')} className="waves-effect waves-light btn-large">
                    Advertisers
                </a> 
            </div>
            {type == "Users" ? 
                <div class="mb-10">
                    <div className="youtube-view">
                       <iframe title="About us" width="700" height="500" style={{margin: 'auto', display: 'block', marginTop: 20}}
                        src={Lang('aboutUserVideoLink')} frameborder="0" allowfullscreen>
                        </iframe>
                    </div>

                    <p class="my-4 w-8/12 mx-auto bg-gray-200 rounded-md p-4">{Lang('aboutUserText')}</p>
                </div>:
                <div>
                    <iframe title="About us" width="700" height="500" style={{margin: 'auto', display: 'block', marginTop: 20}}
                        src={Lang('aboutAdvertiserVideoLink')} frameborder="0" allowfullscreen>
                    </iframe>

                    <p class="my-4 w-8/12 mx-auto bg-gray-200 rounded-md p-4">{Lang('aboutAdvertiserText')}</p>
                </div>}
            
        </div>
    );
}

export default AboutUsPage;
