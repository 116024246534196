export const FETCH_USER = 'fetch-user';
export const FETCH_COUNTRIES = 'fetch-countries';
export const FETCH_PAYMENT = 'fetch-payments';
export const FETCH_ADVERT = 'fetch-advert';
export const FETCH_PATIENT = 'fetch-patient';
export const FETCH_ALL_PATIENTS = 'fetch-patients';
export const FETCH_SELECTED_LANGUAGE = 'fetch-selected-language';
export const FETCH_FILES = 'fetch-files';
export const FETCH_USER_ADVS = 'fetch-advs';
export const FETCH_ARS_USERS = 'fetch-ars';
export const SET_DONATION_DETAILS = 'fetch-donation-details';


