import { FETCH_USER_ADVS } from '../actions/types';

export default function(state = [], action) {
    //console.log(action);
    switch(action.type) {
        case FETCH_USER_ADVS:
            return (action.payload || false);
        default:
            return state;
    }
}