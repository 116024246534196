import { FETCH_COUNTRIES } from '../actions/types';

export default function(state = null, action) {
    //console.log(action);
    switch(action.type) {
        case FETCH_COUNTRIES:
            return (action.payload || false);
        default:
            return state;
    }
}