import React, { useEffect } from 'react';
import { Row, Col } from 'react-materialize';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../asset/css/adminPanel.css';

const AdvertizersPage = props => {
  useEffect(() => {
    props.getAdvertizers();
  }, []);
  
  return (
    <div className="about-us-component">
      <Row>
        <Col m={12} s={12}>
          <table className='admin-panel-table striped'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Balance</th>
                <th>Advs</th>
                <th>Money earned</th>
              </tr>
            </thead>
            <tbody>
              {props.allAdvertizers.Users && props.allAdvertizers.Users.map((user, i) => 
                [<tr key={i}>
                  <td className='admin-panel-table-icon'>
                    <img src={user.userData.photo} alt="" width='50' className="circle" />
                    <a href={'/patient/' + user._id} target="_blank" className='admin-panel-table-name'>{user.userData.firstName} {user.userData.lastName}</a>
                  </td>
                  <td>{user.balance}</td>
                  <td>{user.overallAdvs}</td>
                  <td>{user.userData.total}</td>
                </tr>,
                <tr key={i+100}>
                  <td colSpan="5">
                    <table className='admin-panel-table striped'>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Earnings</th>
                          <th>Mode</th>
                          <th>Clicks purchased</th>
                          <th>Clicks left</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.allAdvertizers.Advs && props.allAdvertizers.Advs.filter(a => a._user === user._id).map((item, index) => 
                          <tr key={index}>
                            <td>{item.title}</td>
                            <td>{item.earn}</td>
                            <td>{item.item}</td>
                            <td>{item.price}</td>
                            <td>{item.clicks}</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </td>
                </tr>
                ]
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    allAdvertizers: state.allAdvertizers || []
  };
}

export default connect(mapStateToProps, actions)(AdvertizersPage);