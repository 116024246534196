import React, { Component } from 'react';
import { Button } from 'react-materialize';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import Lang from '../language/lang.js';
import axios from 'axios';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import defaultDp from '../asset/images/default-dp.png'


class Donation extends Component {

    

    renderContent = (user) => {
        const userData = user.userData;
        const requestPayout = () => {
        
            axios.post("/api/requestPayout")
            .then(response => { 
                if(response.data.success == 'success') {
                    this.props.fetchUser();
                }
                toast(response.data.message);
            })
        };
        return (
            <div className='w-6/12 mx-auto dashboard-ak'>
                <ToastContainer/>
                <div className="row">
                    <div className="col s12">
                        <div className="col s12 ">
                            <ul className="collection">
                                <li className="collection-item avatar">
                                    <img src={userData.photo != '' ? userData.photo : defaultDp} style={{backgroundColor:'white'}} alt="" className="circle" />
                                    <span className="title">
                                        <b>{Lang('hi')} {userData.firstName}!</b>
                                    </span>
                                    <p><b>{Lang('memberSince')}</b>: {user.created ? new Date(user.created).toLocaleDateString() : ''}
                                    </p>
                                    <p><b>{Lang('membership')}</b>: { user.userRole == "Patient" ? 'User' : user.userRole }
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className="col s12">
                            <ul className="collection">
                                <li className="collection-item">
                                    <span className="title">
                                        <b>{Lang('userStats')}</b>
                                    </span>
                                    { user.userRole === 'Advertizer' &&
                                        <div>
                                            <div>{Lang('balance')}: { user.balance.toFixed(4) }</div>
                                            <div>{Lang('overall')}: { user.overallAdvs }</div>
                                        </div>
                                    }
                                    { user.userRole !== 'Advertizer' &&
                                        <div>
                                            <div>{Lang('received')}: { user.userData.ownViewReceived.toFixed(4) }</div>
                                            <div>{Lang('referals')}: { user.userData.received.toFixed(4) }</div>
                                            {/*<div>Viewed ads: { user.overallAdvs }</div>*/}
                                            <div>{Lang('generalAccaunt')}: { user.userData.donated.toFixed(4) }</div>
                                            <div>{Lang('yourClicks')}: { user.userData.ownClicks }</div>
                                        </div>
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="col s12">
                            <ul className="collection">
                                <li className="collection-item">
                                    <div className="row">
                                        { user.userRole !== 'Advertizer' &&
                                            <Link
                                                to={'/advView/' + user._id}
                                                className="waves-effect waves-light btn-small">
                                                {Lang('viewAdvs')}
                                                <i className="material-icons right">monetization_on</i>
                                            </Link>
                                        }
                                        <div style={{display:'flex',gap:'10px'}}>
                                            { user.userRole === 'Advertizer' && <Link
                                                to='/advList'
                                                className="waves-effect waves-light btn-small">
                                                {Lang('addAds')}
                                                <i className="material-icons right">add_to_queue</i>
                                            </Link>}
                                            
                                            { user.userRole === 'Advertizer' &&<Link
                                                to='/payments'
                                                className="waves-effect waves-light btn-small">
                                                {Lang('addFunds')}
                                                <i className="material-icons right">account_balance_wallet</i>
                                            </Link> }
                                            { user.superAdmin &&
                                              <div className="row">
                                                <Link
                                                    to='/admin-panel'
                                                    style={{marginTop: 20, marginLeft:12}}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('adminPanel')}
                                                    <i className="material-icons right"></i>
                                                </Link>
                                                <Link
                                                    to='/adv-panel'
                                                    style={{marginTop: 20, marginLeft:12}}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('advPanel')}
                                                    <i className="material-icons right"></i>
                                                </Link>
                                                <Link
                                                    to='/advertizers-page'
                                                    style={{marginTop: 20, marginLeft:12}}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('advertizersPage')}
                                                    <i className="material-icons right"></i>
                                                </Link>
                                                <Link
                                                    to='/token-requests'
                                                    style={{marginTop: 20, marginLeft:12}}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('tokenRequests')}
                                                    <i className="material-icons right"></i>
                                                </Link>
                                              </div>
                                            }

                                            { user.userRole !== 'Advertizer' && user.userData.total > 0 && user.userData.ownViewReceived < user.userData.total &&
                                                <Button
                                                    onClick={() => requestPayout()}
                                                    style={{backgroundColor:'#ccd3d3'}}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('withdrawFunds')}
                                                    <i className="material-icons right">monetization_on</i>
                                                </Button>
                                            }
                                            { user.userRole !== 'Advertizer' && user.userData.total > 0 && user.userData.ownViewReceived >= user.userData.total &&
                                                <Button
                                                    onClick={() => requestPayout()}
                                                    className="waves-effect waves-light btn-small">
                                                    {Lang('withdrawFunds')}
                                                    <i className="material-icons right">monetization_on</i>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    render() {
        return (
            <div className="dashoboard-component scrollbar" id="scrollbar-style">
                {this.props.auth && this.renderContent( this.props.auth )}
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, actions)(Donation);