import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

class StripePage extends Component {
    render() {
        const url = this.props.location.search === '?success';

        return (
            <div className="paypal-component">
                { url ? 'Cool && Success!' : 'something went wrong!'}
            </div>
        )
    }
};

export default connect(null, actions)(StripePage);