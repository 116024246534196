import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import Lang from '../language/lang.js';
import queryString from 'query-string';

import defaultDp from '../asset/images/default-dp.png'

class UserSettings extends Component {
    componentWillMount() {
        this.props.currentPatient({ userId: this.props.match.params.patientId });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.params.patientId !== nextProps.match.params.patientId) {
            this.props.currentPatient({ userId: nextProps.match.params.patientId });
        }

    }

    setHeightField(e) {
        if (e && e.style) {
            setTimeout(function () {
                e.style.cssText = 'height:' + e.scrollHeight + 'px';
            }, 400);
        }
    }

    renderNoPatient = () => {
        return (<h5> This page is empty, please fill out information in the user's settings </h5>)
    };

    renderPatient = (patientData, photo) => {
        return (<div>
            <div className="avatar">
                <img src={photo != '' ? photo : defaultDp} alt="" style={{ width: '100px', height: '100px', backgroundColor: 'white' }} className="circle" />
            </div>
            <div className="col offset-s0 offset-l3 s12 l6 user-form patientpage" >
                <div className="row">
                    <div className="input-field col s6">
                        <input value={patientData.firstName + ' ' + patientData.lastName} id="firstName" type="text" className="validate" disabled />
                        <label htmlFor="firstName" className="active">{Lang('firstName')}</label>
                    </div>
                    <div className="input-field col s6">
                        <input value={patientData.id} id="number" type="text" className="validate" disabled />
                        <label htmlFor="number" className="active">{Lang('id')}</label>
                    </div>
                    <div className="input-field col s12">
                        <textarea value={patientData.additionalInfo} ref={this.setHeightField} className="validate additionalInfo" disabled ></textarea>
                        <label htmlFor="additionalInfo" className="active">{Lang('additionalInfo')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input value={patientData.diagnosis} id="dose" type="text" className="validate" disabled />
                        <label htmlFor="dose" className="active">{Lang('dose')}</label>
                    </div>
                    <div className="input-field col s3">
                        <input value={'$' + patientData.total} id="price" type="text" className="validate" disabled />
                        <label htmlFor="price" className="active">{Lang('price')}</label>
                    </div>
                    <div className="input-field col s3">
                        <input value={'$' + parseFloat(parseFloat(patientData.received) + parseFloat(patientData.ownViewReceived) + parseFloat(patientData.donated)).toFixed(4)} id="funds" type="text" className="validate" disabled />
                        <label htmlFor="funds" className="active">{Lang('funds')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col s6 offset-s3">
                        <div className="card-container">
                            <Link to={queryString.parse(this.props.location.search).type == 'search' ? `/advView/5bf6ae1dc590680015156c18?type=search&pid=${this.props.patient.id}` : '/advView/' + this.props.patient.id}
                                className="button-item">
                                <div className="blue hoverable darken-1 h-10 justify-center flex items-center rounded">
                                    <div className="">
                                        {Lang('watchAdv')}
                                    </div>
                                </div>
                            </Link>
                            {/*<div className="col s12">
                                    <Link to={'/surveys/'+this.props.patient.id}
                                        className="button-item">
                                        <div className="card blue hoverable darken-1">
                                            <div className="card-action">
                                                Go to Surveys
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col s12">
                                    <Link to={'/advertisement/'+this.props.patient.id}
                                        className="button-item">
                                        <div className="card blue hoverable darken-1">
                                            <div className="card-action">
                                                Wants to play?
                                            </div>
                                        </div>
                                    </Link>
                                </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    };

    render() {
        let photo = '';

        if (this.props.patient && !this.props.patient.error) {
            photo = this.props.patient.photo.replace('?sz=50', '?sz=100');
        }

        return (
            <div className="scrollbar" id="scrollbar-style">
                <div className="row user-settings patient-settings scrollbar">
                    {this.props.patient && !this.props.patient.error ?
                        this.renderPatient(this.props.patient, photo) :
                        this.renderNoPatient()
                    }
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        patient: state.patient,
    };
}

export default connect(mapStateToProps, actions)(UserSettings);