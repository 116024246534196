import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Link } from 'react-router-dom';
import Lang from '../language/lang.js';
import ReactDOM from 'react-dom';
import axios from 'axios';
import TabVisibility from 'react-tab-visibility';
import queryString from 'query-string';

let Interval;

class AdvView extends TabVisibility {
    state = {
        interval: null,
        currentAdv: {},
        showAdv: false,
        timePassed: 0,
        paused: false,
    };

    componentDidMount() {
        // document.addEventListener('click', this.handleClickOutside, true);
        this.props.getAdvs(localStorage.getItem('pcId'));

        if (queryString.parse(this.props.location.search).type == 'search') {
            axios.post('/api/getFunds', { id: queryString.parse(this.props.location.search).pid }).then((res) => {
                document.getElementById("fund-amount").innerHTML = `$${(parseFloat(res.data.user.userData.received.toFixed(4)) +
                    parseFloat(
                        res.data.user.userData.ownViewReceived.toFixed(4)
                    ))}`
                console.log(res.data.user)
                document.getElementById("search-label").innerHTML = `${Lang('patient')} ${res.data.user.userData.firstName} ${res.data.user.userData.lastName}`
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    componentWillMount() {
        //        this.props.getAdvs(localStorage.getItem('pcId'));
        let userId = this.props.match.params.patientId;
        if (userId !== (this.props.patient && this.props.patient.id)) {
            this.props.currentPatient({ userId: userId });
        }
    };

    componentWillUnmount() {
        // document.removeEventListener('click', this.handleClickOutside, true);
        // document.getElementById("fund-amount").innerHTML = ''
    }

    onTabVisibilityChange(status) {
        this.setState({ paused: !status.tabIsVisible });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.patientId !== this.props.match.params.patientId) {
            this.props.currentPatient({ userId: nextProps.match.params.patientId });
        }
    }

    deleteAdv = (id) => {

        if (confirm("Are you sure want to delete?")) {
            const response = axios.delete(`/api/delete-adv/${id}`).then((response) => {
                if (response.data.message == 'success') {
                    var url = new URL(window.location.href);
                    window.location.href = `${url.href}`;
                }
            }).catch((err) => {
                console.log(err)
            })
        }

    }

    handleClickOutside = event => {
        console.log(event.target)

        if (event.target.tagName === 'BUTTON') {
            console.log('Clicked element is a button.');
        } else {
            window.location.href = this.state.currentAdv.targetUrl
        }


        // const domNode = ReactDOM.findDOMNode(this);

        // if (domNode || !domNode.contains(event.target)) {
        //     window.location.href = this.state.currentAdv.targetUrl
        // }
    }

    handleIframeClick = (event) => {
        // Prevent iframe click from propagating to document
        event.stopPropagation();
    };


    onStartWatch = () => {
        let x = 0;
        let width = 0;
        let seconds = parseFloat(this.state.currentAdv.item.match(/\d+/)[0]);
        const piece = 100 / seconds;

        if (this.state.timePassed > 0) {
            return false;
        }
        Interval = setInterval(function () {
            if (!this.state.paused) {
                x += 1;
                width += piece;
                if (x === seconds) {
                    clearInterval(Interval);
                    this.addUserFunds();
                }
                this.setState({ timePassed: width, Interval });
            }
        }.bind(this), 1000);
    };

    addUserFunds = () => {
        let adverts = [...this.props.advs];
        let currentAdv = { ...this.state.currentAdv };
        let { parent, children, _id } = { ...this.state.currentAdv };

        if (!localStorage.getItem('pcId')) {
            const randomId = parseFloat(Math.random());
            localStorage.setItem('pcId', randomId);
        }

        // const adViewedAs = this.props.match.params.patientId !== '5bf6ae1dc590680015156c18' ? 
        const adViewedAs = this.props.auth ? 'user-it-self' : this.props.match.params.patientId !== '5bf6ae1dc590680015156c18' ? 'refrence' : queryString.parse(this.props.location.search).type == 'search' ? 'refrence' : 'guest'

        const data = {
            userId: queryString.parse(this.props.location.search).type == 'search' ? queryString.parse(this.props.location.search).pid : this.props.match.params.patientId,
            logged: this.props.auth ? true : false, //separation for funds. own and help
            adsId: _id,
            pcId: localStorage.getItem('pcId'),
            adViewedAs: adViewedAs
        }
        this.props.setMoneyData(data);
        adverts[parent].list[children].seen = true;
        this.props.change(adverts);
        currentAdv.seen = true;
        this.setState({ currentAdv });

        axios.post('/api/getFunds', { id: queryString.parse(this.props.location.search).pid }).then((res) => {
            document.getElementById("fund-amount").innerHTML = `$${(parseFloat(res.data.user.userData.received.toFixed(4)) +
                parseFloat(
                    res.data.user.userData.ownViewReceived.toFixed(4)
                ))}`
        }).catch((error) => {
            console.log(error)
        })
    };

    getVisibleVideos = (item) => {
        let count = 0;

        item.list.forEach(function (element) {
            if (!element.seen) {
                count += 1;
            }
        });

        return count;
    };

    onAdvClick = (parent, children) => {
        let currentAdv = { ...this.props.advs[parent].list[children] };

        currentAdv.parent = parent;
        currentAdv.children = children;

        this.setState({ currentAdv, showAdv: true, timePassed: 0 });
    };

    removeInterval = () => {
        clearInterval(Interval);
        this.setState({ showAdv: false })
    };

    renderAdv = () => {
        if (!this.state.currentAdv.seen) {
            !global.adDetected && this.onStartWatch();
        }
        return (
            <div className="col s12">
                <div className="progress">
                    <div className="determinate" style={{ width: this.state.timePassed + '%' }}></div>
                </div>
                <div className="video-container relative">
                    <iframe title='redcs'
                        src={this.state.currentAdv.targetUrl}
                        sandbox="allow-same-origin allow-scripts allow-forms allow-popups"
                        id="myId">
                    </iframe>
                </div>
            </div>
        );
    };


    renderAdvList = () => {
        if (!this.props.advs) {
            return false;
        }

        return (
            this.props.advs.map((item, i) =>
                <div key={item.title} className="col s12">
                    <ul className="collapsible">
                        <li>
                            <div className="collapsible-header">
                                <i className="material-icons">tv</i>
                                {item.title}
                                <span className="new badge">{this.getVisibleVideos(item)}</span>
                            </div>
                            <div className="collapsible-body col m12">
                                {item.list.reverse().map((el, y) =>
                                    <div key={el._id} className="col s12 m4 l3">
                                        <div className={'card horizontal ' + (el.seen ? 'seen' : '')}>
                                            <div className="valign-wrapper">
                                                <img alt="" src={el.previewUrl} />
                                            </div>
                                            <div className="card-stacked">
                                                <div className="card-content flex items-center justify-between">
                                                    <p>{el.title}</p>
                                                    {
                                                        this.props.auth.superAdmin ?
                                                            <svg onClick={() => this.deleteAdv(el._id)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                                                                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                                            </svg>
                                                            : ''
                                                    }
                                                </div>
                                                <div className="card-action">
                                                    <button
                                                        onClick={() => this.onAdvClick(i, y)}
                                                        className="waves-effect waves-light col m12 right btn-small">
                                                        {el.seen ? Lang('seen') : `${Lang('watchFor')} $${el.earn}`}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </li>
                    </ul>
                </div>
            )
        )
    };

    render() {
        return (
            <div className="adv-view-component" id="scrollbar-style">
                <div className="row">
                    <div className="col s12 btn-group">

                        {/* Back to a button when showing ad */}
                        {this.state.showAdv &&
                            <button className="waves-effect waves-light right back-button btn-small"
                                onClick={this.removeInterval}

                            >{Lang('backToAds')}
                            </button>
                        }
                        {this.props.match.params.patientId !== '5bf6ae1dc590680015156c18' ?
                            // back to patent detail page
                            <Link to={'/patient/' + this.props.match.params.patientId}
                                onClick={this.removeInterval}
                                className="waves-effect right waves-light btn-small back-button">
                                {Lang('backToPatient')}
                                <i className="material-icons right">arrow_back</i>
                            </Link>
                            :
                            // when user search for id and then view ad for him
                            queryString.parse(this.props.location.search).type == 'search' ?
                                <Link to={'/patient/' + queryString.parse(this.props.location.search).pid + "?type=search"}
                                    onClick={this.removeInterval}
                                    className="waves-effect right waves-light btn-small back-button">
                                    {Lang('backToPatient')}
                                    <i className="material-icons right">arrow_back</i>
                                </Link>
                                :
                                // when guest user is viewing the ad
                                <Link to={'/guest'}
                                    onClick={this.removeInterval}
                                    className="waves-effect right waves-light btn-small back-button">
                                    {Lang('backToPage')}
                                    <i className="material-icons right">arrow_back</i>
                                </Link>
                        }
                    </div>
                    {this.state.showAdv ? this.renderAdv() : this.renderAdvList()}
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        advs: state.advs,
        patient: state.patient,
    };
}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'paymentData',
    enableReinitialize: true
})(AdvView));
