import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { reduxForm, Field } from "redux-form";
import { Link } from "react-router-dom";
import Logo from "../asset/icons/logo.png";
import Lang from "../language/lang.js";
import { MdMenu } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import queryString from 'query-string';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileNavbar: false,
      showmobilesearchbox: false,
      search: false,
      name: ''
    };
    // Bind the context of `this` to the toggleMobileNavbar method
    this.toggleMobileNavbar = this.toggleMobileNavbar.bind(this);
    this.showsearchsection = this.showsearchsection.bind(this);
    this.toggleSearch = this.toggleSearch(this);
    this.setName = this.setName(this);
  }

  toggleMobileNavbar() {
    this.setState({ showMobileNavbar: !this.state.showMobileNavbar });
  }
  showsearchsection() {
    this.setState({ showmobilesearchbox: !this.state.showmobilesearchbox });
  }

  toggleSearch() {
    this.setState({ search: !this.state.search })
  }

  setName(name) {
    this.setState({ name: name });
  }

  componentWillReceiveProps(nextProps) {
    let userId = window.location.pathname.split("/")[2];
    if (!nextProps.patient && userId) {
      this.props.currentPatient({ userId: userId });
    }
    if (this.props.location) {
      if (queryString.parse(this.props.location.search).type == 'search') {
        this.setName("test");
        this.toggleSearch();
      }
    }
  }

  renderContent = (patient) => {
    switch (this.props.auth) {
      case null:
        return;
      case false:
        return [
          <span key="1" className="left">
            <li>
              <Link to="/" style={{ margin: "0 10px" }} className="logo">
                <img src={Logo} alt="" className="logo-icon" />
              </Link>
            </li>
            <li id="search-label">
              {this.props.patient &&
                this.props.patient.lastName &&
                Lang("patient") +
                " " +
                this.props.patient.lastName +
                " " +
                this.props.patient.firstName}
            </li>
            <li className="search-wrapper">
              <form
                className="input-dropdowns"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Field
                  name="patient"
                  component="input"
                  type="text"
                  className="form-control validate"
                  placeholder={Lang("searchText")}
                />
              </form>
            </li>
            {/* search icon for mobile */}
            <li className="mobile-search-icon">
              <button onClick={this.showsearchsection}>
                <FaSearch color="#fff" />
              </button>
            </li>
            {/* search inputfield section for mobile */}
            <div className={
              this.state.showmobilesearchbox
                ? "show-mobile-search-section"
                : "hide-mobile-search-section"
            }>
              <form
                className="input-dropdowns"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <Field
                  name="patient"
                  component="input"
                  type="text"
                  className="form-control validate"
                  placeholder={Lang("searchText")}
                />
              </form>
              <Link
                className={!this.props.patientId ? "disabled-link" : ""}
                to={"/patient/" + this.props.patientId + "?type=search"}
              >
                {Lang("search")}
              </Link>
            </div>

            <li className={!this.props.patientId ? "disabled-cursor" : ""} id="search-btn">
              <Link
                className={!this.props.patientId ? "disabled-link" : ""}
                to={"/patient/" + this.props.patientId + "?type=search"}
              >
                {Lang("search")}
              </Link>
            </li>
          </span>,

          <span key="2" className="right">

            {/*<li>
                            <div className="input-dropdowns">
                                <input type="text" className="form-control" placeholder="Drug Search" list="cars" />
                                <datalist id="cars">
                                    <option>Volvo</option>
                                    <option>Saab</option>
                                    <option>Mercedes</option>
                                    <option>Audi</option>
                                </datalist>
                            </div>
                        </li>*/}


            <li id="fund-amount">
            </li>
            <li>
              <Link to="/login">{Lang("login")}</Link>
            </li>
          </span>,
        ];
      default:
        return [
          <span key="1" className="left">
            <li key="6">
              <Link to="/" style={{ margin: "0 10px" }} className="logo">
                <img src={Logo} alt="" className="logo-icon" />
              </Link>
            </li>
          </span>,
          <div className="">
            {/* //mobile menu icons for  dashboard menu */}
            <div className="mobile-menu-icons">
              <button onClick={this.toggleMobileNavbar}>
                <MdMenu />
              </button>
            </div>
            <span key="2" className="right dashbord-navbar">
              {this.props.auth.userRole == "Patient" &&
                window.location.pathname.substring(0, 8) == "/advView" ? (
                <li>
                  {'$' + parseFloat(parseFloat(this.props.auth.userData.received) + parseFloat(this.props.auth.userData.ownViewReceived) + parseFloat(this.props.auth.userData.donated)).toFixed(4)}

                  {/* {"$" +
                    (parseFloat(this.props.auth.userData.received.toFixed(4)) +
                      parseFloat(
                        this.props.auth.userData.ownViewReceived.toFixed(4)
                      ))} */}
                </li>
              ) : (
                ""
              )}

              {this.props.auth.userRole === "Advertizer" && (
                <li key="2" style={{ margin: "0 10px" }}>
                  {" "}
                  {Lang("purchaseBalance")}: {this.props.auth.balance}{" "}
                </li>
              )}
              {this.props.auth.userRole !== "Advertizer" && (
                <li key="5" style={{ margin: "0 10px" }}>
                  <Link to={"/patient/" + this.props.auth._id}>
                    {Lang("myPage")}
                  </Link>
                </li>
              )}
              <li key="4">
                <Link to="/dashboard">{Lang("dashboard")}</Link>
              </li>
              <li key="1">
                <Link to="/user-settings">{Lang("userSettings")}</Link>
              </li>
              <li key="3">
                {" "}
                <a href="/api/logout">{Lang("logout")}</a>{" "}
              </li>
            </span>
            {/* mobile navbar */}
          </div>,
        ];
    }
  };

  render() {
    const { patient } = this.props;
    return (
      <nav className="bottom-radius">
        <div className="nav-wrapper blue darken-1 bottom-radius">
          {/*<div className="tes">*/}
          <ul id="nav-mobile">{this.renderContent(patient)}</ul>
          <div
            className={
              this.state.showMobileNavbar
                ? "show-mobile-navbar"
                : "hide-mobile-navbar"
            }
          >
            {this.props.auth && (
              <ul>
                {this.props.auth.userRole === "Patient" &&
                  window.location.pathname.substring(0, 8) === "/advView" && (
                    <li key="advView">
                      {"$" +
                        (parseFloat(
                          this.props.auth.userData.received.toFixed(4)
                        ) +
                          parseFloat(
                            this.props.auth.userData.ownViewReceived.toFixed(4)
                          ))}
                    </li>
                  )}

                {this.props.auth.userRole === "Advertizer" && (
                  <li key="purchaseBalance" style={{ margin: "0 10px" }}>
                    {Lang("purchaseBalance")}: {this.props.auth.balance}
                  </li>
                )}

                {this.props.auth.userRole !== "Advertizer" && (
                  <li key="myPage" style={{ margin: "0 10px" }}>
                    <Link to={"/patient/" + this.props.auth._id}>
                      {Lang("myPage")}
                    </Link>
                  </li>
                )}

                <li key="dashboard">
                  <Link to="/dashboard">{Lang("dashboard")}</Link>
                </li>
                <li key="userSettings">
                  <Link to="/user-settings">{Lang("userSettings")}</Link>
                </li>
                <li key="logout">
                  <a href="/api/logout">{Lang("logout")}</a>
                </li>
              </ul>
            )}
          </div>


          {/*</div>*/}
        </div>

      </nav>
    );
  }
}

function mapStateToProps(state) {
  let patientId = "";
  if (state.form && state.form.patient && state.form.patient.values) {
    patientId = state.form.patient.values.patient;
  }
  return {
    auth: state.auth,
    patient: state.patient,
    patientId: patientId,
    selectedLanguage: state.selectedLanguage,
  };
}

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "patient",
    enableReinitialize: true,
  })(Header)
);
