import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Lang from '../language/lang.js';

class Payments extends Component {
    /*
    state = {
        paymentSystems: [{
            amount: 50,
            name: 'Stripe',
            button: 'stripe',
            src: require('../asset/icons/stripe.png')
        }, {
            amount: 50,
            name: 'PayPal',
            button: 'payPal',
            src: require('../asset/icons/paypal.jpg')
        }]
    };
    */
    state = {
        paymentSystems: [{
            amount: 50,
            name: 'PayPal',
            button: 'payPal',
            src: require('../asset/icons/paypal.jpg')
        }]
    };

    handleStripe = async (token, amount, isDonation) => {
        this.setState({buttonsDisabled: true});
        let data = await this.props.handleStripes(token, amount, isDonation);

        this.props.history.push(data.status === 'success' ? '/stripe?success' : '/stripe?fail');
    };

    onChange = (e, i) => {
        const { value } = e.target;
        const paymentSystems = [...this.state.paymentSystems];

        paymentSystems[i].amount = value;

        this.setState({ paymentSystems });
    };

    getButton = (button, amount) => {
        const isDonation = this.props.match.url === '/donation';
        if (button === 'stripe') {
            return (
                <StripeCheckout
                    name='supium.com'
                    description={Lang('addFunds')}
                    amount={amount * 100}
                    token={token => {
                        this.setState({buttonsDisabled: true});
                        this.handleStripe(token, amount, isDonation)
                    }}
                    stripeKey={process.env.REACT_APP_STRIPE_KEY} >
                        <button className="btn-floating halfway-fab btn waves-effect waves-light"
                            disabled={this.state.buttonsDisabled}>
                            {Lang('add')}
                            <i className="material-icons right">send</i>
                        </button>
                </StripeCheckout>
            )
        }
        if (button === 'payPal') {
            return (
                <button className="btn-floating halfway-fab btn waves-effect waves-light"
                    onClick={() => {
                        this.setState({buttonsDisabled: true});
                        this.props.handlePaypal(amount, isDonation, true)}
                    }
                    disabled={this.state.buttonsDisabled}>
                    {Lang('add')}
                    <i className="material-icons right">send</i>
                </button>
            )
        }
    }

    render() {
        const { paymentSystems } = this.state;

        return (
            <div className="payment-component mt-40">
                <div className="w-2/12 mx-auto donation-ak">
                    { paymentSystems.map((item, i) =>   
                        <div key={i} className="w-full ">
                            <div className="card payment-card">
                                <div className="card-image">
                                    <img src={ item.src } alt="" />
                                    <span className="card-title">{ item.name }</span>
                                    {this.getButton(item.button, item.amount)}
                                </div>
                                <div className="card-content">
                                    <div className="input-field browser-default">
                                        <input id="amount" type="number" min="0" className="form-control right amount"
                                            value={item.amount}
                                            onChange={(e) => this.onChange(e, i)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    };
}

export default connect(null, actions)(Payments);