import React, { useEffect } from 'react';
import { Row, Col, Icon, Button } from 'react-materialize';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../asset/css/adminPanel.css';

const AdminPage = props => {
  useEffect(() => {
    props.getAllPatients();
  }, []);

  const onBuyMedsClick = async (user) => {
    await props.onBuyMedsClick(user._id);

    props.getAllPatients();
  };
  
  return (
    <div className="about-us-component">
      <Row>
        <Col m={12} s={12}>
          <table className='admin-panel-table striped'>
            <thead>
              <tr>
                <th>Name</th>
                <th>Item Name</th>
                <th>Drags name and dose</th>
                <th>Buy</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {props.allPatients.map((item, i) => 
                <tr key={i}>
                  <td className='admin-panel-table-icon'>
                    <img src={item.userData.photo} alt="" width='50' className="circle" />
                    <a href={'/patient/' + item._id} target="_blank" className='admin-panel-table-name'>{item.userData.firstName} {item.userData.lastName}</a>
                  </td>
                  <td>{item.userData.received}$ / {item.userData.total}$</td>
                  <td>{item.userData.diagnosis}</td>
                  <td>
                    {(item.userData.total <= item.userData.received) && item.userData.total !== 0 &&
                      <Button waves="light" disabled={item.userData.medsBought} style={{marginRight: '5px'}} onClick={() => onBuyMedsClick(item)}>
                        Buy
                        <Icon left>
                          cloud
                        </Icon>
                      </Button>
                    }
                  </td>
                  <td>
                    {item.userData.medsBought ?
                      <Icon left>
                        check
                      </Icon>
                      :
                      null
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  )
};

function mapStateToProps(state) {
  return {
    allPatients: state.allPatients || []
  };
}

export default connect(mapStateToProps, actions)(AdminPage);