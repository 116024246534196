const adverts = [{
    title: 'P(5s)',
    dropdownName: 'p',
    ptc: 0.0025,
    dropdown: [{
        label: '500 clicks - $1.5',
        value: 1.5,
        clicks: 500
    }, {
        label: '1000 clicks - $3',
        value: 3,
        clicks: 1000
    }, {
        label: '5000 clicks - $15',
        value: 15,
        clicks: 5000
    }, {
        label: '10000 clicks - $30',
        value: 30,
        clicks: 10000
    }, {
        label: '50000 clicks - $150',
        value: 150,
        clicks: 50000
    }, {
        label: '100000 clicks - $300',
        value: 300,
        clicks: 100000
    }]
}, {
    title: 'XS(10s)',
    dropdownName: 'xs',
    ptc: 0.005,
    dropdown: [{
        label: '500 clicks - $3',
        value: 3,
        clicks: 500
    }, {
        label: '1000 clicks - $6',
        value: 6,
        clicks: 1000
    }, {
        label: '5000 clicks - $30',
        value: 30,
        clicks: 5000
    }, {
        label: '10000 clicks - $60',
        value: 60,
        clicks: 10000
    }, {
        label: '50000 clicks - $300',
        value: 300,
        clicks: 50000
    }, {
        label: '100000 clicks - $600',
        value: 600,
        clicks: 100000
    }]
}, {
    title: 'S(15s)',
    dropdownName: 's',
    ptc: 0.01,
    dropdown: [{
        label: '500 clicks - $6',
        value: 6,
        clicks: 500
    }, {
        label: '1000 clicks - $12',
        value: 12,
        clicks: 1000
    }, {
        label: '5000 clicks - $60',
        value: 60,
        clicks: 5000
    }, {
        label: '10000 clicks - $120',
        value: 120,
        clicks: 10000
    }, {
        label: '50000 clicks - $600',
        value: 600,
        clicks: 50000
    }, {
        label: '100000 clicks - $1200',
        value: 1200,
        clicks: 100000
    }]
}, {
    title: 'M(30s)',
    dropdownName: 'm',
    ptc: 0.02,
    dropdown: [{
        label: '500 clicks - $12',
        value: 12,
        clicks: 500
    }, {
        label: '1000 clicks - $24',
        value: 24,
        clicks: 1000
    }, {
        label: '5000 clicks - $120',
        value: 120,
        clicks: 5000
    }, {
        label: '10000 clicks - $240',
        value: 240,
        clicks: 10000
    }, {
        label: '50000 clicks - $1200',
        value: 1200,
        clicks: 50000
    }, {
        label: '100000 clicks - $2400',
        value: 2400,
        clicks: 100000
    }]
}, {
    title: 'L(45s)',
    dropdownName: 'l',
    ptc: 0.03,
    dropdown: [{
        label: '500 clicks - $18',
        value: 18,
        clicks: 500
    }, {
        label: '1000 clicks - $36',
        value: 36,
        clicks: 1000
    }, {
        label: '5000 clicks - $180',
        value: 180,
        clicks: 5000
    }, {
        label: '10000 clicks - $360',
        value: 360,
        clicks: 10000
    }, {
        label: '50000 clicks - $1800',
        value: 1800,
        clicks: 50000
    }, {
        label: '100000 clicks - $3600',
        value: 3600,
        clicks: 100000
    }]
}, {
    title: 'XL(60s)',
    dropdownName: 'xl',
    ptc: 0.05,
    dropdown: [{
        label: '500 clicks - $30',
        value: 30,
        clicks: 500
    }, {
        label: '1000 clicks - $60',
        value: 60,
        clicks: 1000
    }, {
        label: '5000 clicks - $300',
        value: 300,
        clicks: 5000
    }, {
        label: '10000 clicks - $600',
        value: 600,
        clicks: 10000
    }, {
        label: '50000 clicks - $3000',
        value: 3000,
        clicks: 50000
    }, {
        label: '100000 clicks - $6000',
        value: 6000,
        clicks: 100000
    }]
}];

export default adverts;