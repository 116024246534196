import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Datetime from 'react-datetime';
import Lang from '../language/lang.js';

import defaultDp from '../asset/images/default-dp.png'

import axios from 'axios';


class UserSettings extends Component {
    state = {
        files: [],
        loading: false,
        new_password: '',
        confirm_password: '',
        form_error: '',
        form_success: '',
        profile_uploading: false,
        emailVerificationRequestSuccess: false
    };

    componentWillMount() {
        if (!this.props.files) {
            this.props.getUploadedFiles();
        }
    };

    uploadHandler = (e, item) => {
        let file = e.target.files[0];
        const req = new XMLHttpRequest();
        const formData = new FormData();
        formData.append("fileToUpload", file, file.name);

        this.setState({loading: true});

        req.open("POST", "/api/upload");
        req.send(formData);
        const self = this;
        req.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            self.props.getUploadedFiles();
            self.setState({loading: false});
          }
        };
    };

    onCopyClick = (id) => {
        const el = document.createElement('textarea');
        el.value = `${window.location.origin}/patient/${id}`;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    handleDateChange = (e) => {
        const date = `${e._d.getDate()}.${e._d.getMonth()+1}.${e._d.getFullYear()}`;
        this.props.dispatch(change('userData', 'dob', date));
        this.props.user.userData.dob = date;
	
        this.setState({updated:true})
    };

    setHeightField(e) {
        if (e && e.style) {
            setTimeout(function() {
                e.style.cssText = 'height:' + e.scrollHeight + 'px';
            }, 400);
        }
    }

    updateNewPassword = (e) => {
        this.setState({
            new_password: e.target.value
        })
    }

    updateConfirmPassword = (e) => {
        this.setState({
            confirm_password: e.target.value
        })
    }

    updatePassword = (e) => {
        e.preventDefault()
        if(this.state.new_password.length <= 5){
            this.setState({
                form_success: '',
                form_error: "New password can't be empty"
            })
        }else if(this.state.confirm_password != this.state.new_password){
            this.setState({
                form_success: '',
                form_error: "Password does not match"
            })
        }else{
            axios.post('/api/update-password',{email:this.props.user.userData.email,password:this.state.confirm_password}).then((res) => {
                if(res.data.message == 'success'){
                    this.setState({
                        form_error: '',
                        form_success: 'Password successfully updated'
                    })
                }else if(res.data.message == 'error'){
                    this.setState({
                        form_success: '',
                        form_error: res.data.error_message
                    })
                }
            }).catch((err) => console.log(err))
        }
    }

    handleImageChange = (e) => {
        this.setState({
            profile_uploading: true
        })
        const file = e.target.files[0];

        const req = new XMLHttpRequest();
        const formData = new FormData();
        formData.append("fileToUpload", file, file.name);

        axios.post('/api/profile/upload',formData).then(async (res) => {
            if(res.data.message == 'success'){
                await this.props.fetchUser()
                this.setState({
                    profile_uploading: false
                })
            }else if(res.data.message == 'error'){
                console.log(res.data.error_message)
                this.setState({
                    profile_uploading: false
                })
            }
        }).catch((err) => {
            console.log(err)
            this.setState({
                profile_uploading: false
            })
        })
    };

    requestEmailVerification = () => {
        axios.post('/api/verify-email-request').then((res) => {
            if(res.data.message == 'success'){
                this.setState({
                    emailVerificationRequestSuccess: true
                })
            }
        }).catch((err) => console.log(err))
    }

    renderForm = (renderField, renderTextArea, onKeyPress, uploadPrescr, userRole, fileName, language,emailVerified) => {
        console.log(emailVerified)
        return (
            <div className='user-form'>
                
                <form onSubmit={this.props.handleSubmit( this.props.saveUserInfo )}
                    onKeyPress={(e) => onKeyPress(e)}
                    className="col s12 l6 offset-s0 offset-l3 mb-4">
                        {!emailVerified && <div id="alert-4" className="flex items-center p-4 mb-4 rounded-lg bg-yellow-200 text-black border-2 border-yellow-400" role="alert">
                            <svg className="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                            </svg>
                            <span className="sr-only">Info</span>
                            <div className="ml-3 text-sm font-medium">
                                { !this.state.emailVerificationRequestSuccess ? <h1>Email verification required. <a href="#" onClick={this.requestEmailVerification} className="font-semibold underline hover:no-underline">Click here</a> to verify.</h1>
                                : <h1>We've sent you an email with a verification link. Please check your inbox (including spam) for the email. Click the link in the email to verify your email address and complete the registration process.</h1>}
                            </div>
                        </div>}
                    <div className="row">
                        <div className="input-field col s6">
                            <Field name="firstName" component={ renderField } type="text" className="validate" required />
                            <label htmlFor="firstName" className="active">{Lang('firstname')}</label>
                        </div>
                        <div className="input-field col s6">
                            <Field name="lastName" component={ renderField } type="text" className="validate" required />
                            <label htmlFor="lastName" className="active">{Lang('lastName')}</label>
                        </div>

                        {userRole && 
                            <div>
                                <div className="input-field col s12">
                                    <Field name="additionalInfo" component={ renderTextArea } type="text" className="materialize-textarea" />
                                    <label htmlFor="additionalInfo" className="active">{Lang('additionalInfo')}</label>
                                </div>
                                <div className="input-field col s6"> </div>

                                {/* <div className="input-field col s6">
                                    <Datetime
                                        type="text"
                                        inputProps={{
                                            readOnly: true//user is not allowed changed the date from input
                                        }}
                                        closeOnSelect={true}
                                        timeFormat={false}
                                        utc={false}
                                        dateFormat="DD.MM.YYYY"
                                        value={this.props.user.userData.dob || ''}
                                        onChange={(event) => this.handleDateChange(event)}
                                    />
                                    <label htmlFor="dob" className="active">{Lang('dob')}</label>
                                </div> */}

                                <div className="input-field col s8">
                                    <Field name="id" component={ renderField } type="text" className="validate" />
                                    <label htmlFor="id" className="active">{Lang('id')}</label>
                                </div>
                                <div className="input-field col s4">
                                    <button
                                        style={{width: "100%", lineHeight: "12.4px"}}
                                        className="waves-effect waves-light right btn-small"
                                        onClick={() => this.onCopyClick(this.props.user.userData.id)} >{Lang('copy')}
                                    </button>
                                </div>
                            </div>
                        }
                        <div className="input-field col s12">
                            <Field name="email" component={ renderField } type="email" className="validate" />
                            <label htmlFor="email" className="active">{Lang('email')}</label>
                        </div>
                        {/* <div className="input-field col s6">
                            <Field name="phone" component={ renderField } type="number" className="validate" />
                            <label htmlFor="phone" className="active">{Lang('phone')}</label>
                        </div> */}
                        {userRole &&
                            <div>
                                {/* <div className="input-field col s12">
                                    <Field name="address" component={ renderField } type="text" className="materialize-textarea" required />
                                    <label htmlFor="address" className="active">{Lang('address')}</label>
                                </div> */}
                                <div className="input-field col s6">
                                    <Field name="diagnosis" component={ renderField } type="text" className="validate" required />
                                    <label htmlFor="diagnosis" className="active">{Lang('diagnosis')}</label>
                                </div>
                                <div className="input-field col s6">
                                    <Field name="total" component={ renderField } type="number" className="validate" required />
                                    <label htmlFor="total" className="active">{Lang('total')}</label>
                                </div>
                            </div>
                        }
                    </div>
                    {userRole &&
                        <div className="row">
                            {/* <div className="input-field col s6">
                                {this.state.loading ?
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{Lang('loading')}</button>
                                    </div>
                                    :
                                    <div className="upload-btn-wrapper">
                                        <button className="btn">{Lang('file')}</button>
                                        <input name="uploadId" type="file" onChange={(e) => this.uploadHandler(e, 'uploadId')} className="materialize-textarea" />
                                    </div>
                                }
                            </div> */}
                            {this.props.files && this.props.files.map((file, i) => 
                                <div key={i} className="input-field col s12">
                                    <div className="col s6">{ file.name }</div>
                                    {/* <a href={file.url} target="_blank">Download</a> */}
                                    <button className="waves-effect waves-light right col s3 btn-small"
                                        onClick={() => this.props.removeFile(file.gcName)}>remove File</button>
                                </div>
                            )}
                            {/*<div className="input-field col s6">
                                <div className="upload-btn-wrapper">
                                    <button className="btn">Upload Prescription</button>
                                    <input name="uploadPrescr" onChange={(e) => this.uploadHandler(e, 'uploadPrescr')} type="file" className="materialize-textarea" />
                                </div>
                            </div>
                            
                            <div className="input-field col s6">
                                <div className="file-field input-field">
                                    <div className="btn">
                                        <span>{Lang('file')}</span>
                                        <input type="file" onChange={(e) => this.uploadHandler(e, 'uploadPrescr')} />
                                    </div>
                                    <div className="file-path-wrapper">
                                        <input className="file-path validate" value={fileName} type="text" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-field col s6">
                                <img src={ uploadPrescr } alt="" className="square" />
                            </div>*/}
                        </div>
                    }
                    <button
                        className="waves-effect waves-light right col s3 btn-small"
                        >{Lang('submit')}</button>
                </form>
                <form className="col s12 l6 offset-s0 offset-l3 mb-4">
                    <div className="row">
                        <div className=" col s6 mx-auto text-white">
                            <label htmlFor="new_password" className="block mb-2 text-md text-gray-900">New Password</label>
                            <input type='password' name="new_password" className='text-black' onKeyUp={(event) => this.updateNewPassword(event)}></input>
                        </div>
                        <div className=" col s6 mx-auto text-white">
                            <label htmlFor="confirm_password" className="block mb-2 text-md text-gray-900">Confirm Password</label>
                            <input type='password' name="confirm_password" className='text-black' onKeyUp={(event) => this.updateConfirmPassword(event)}></input>
                        </div>
                        <div className="red-text"> { this.state.form_error && this.state.form_error } </div>
                        <div className="green-text"> { this.state.form_success && this.state.form_success } </div>
                    </div>
                    <button onClick={(event) => this.updatePassword(event)}
                        className="waves-effect waves-light right col s3 btn-small"
                        >Update password</button>
                </form>
            </div>
        )
    }

    render() {
        let photo = '';
        let uploadPrescr = '';
        let userRole = '';
        let fileName = '';
        let emailVerified = false;
        
        const renderField = ({ input, label, type, meta: { error, touched } }) => (
            <div>
                <label>{label}</label>
                <div>
                    <input {...input} disabled={ type === 'email' || input.name === 'id' ? true : false } placeholder={ label } type={ type }/>
                    <div className="red-text"> { touched && error } </div>
                </div>
            </div>
        );

        const renderTextArea = ({ input, label, type, meta: { error, touched } }) => (
            <div>
                <textarea ref={this.setHeightField} {...input} type={ type }/>
                <div className="red-text"> { touched && error } </div>
            </div>
        );

        const onKeyPress = (event) => {
            if (event.key === 'Enter' && event.target.localName !== 'textarea') {
                event.preventDefault(); //stops the form from being submitted on Enter
            }
        };

        if (this.props.user) {
            photo = this.props.user.userData.photo.replace('?sz=50', '?sz=100');
            uploadPrescr = this.props.user.userData.uploadPrescr;
            userRole = this.props.user.userRole === 'Patient';
            fileName = this.props.user.userData.fileName;
            emailVerified = this.props.user.userData.emailVerified

            if (!this.props.user.userRole) {
                const userData = {...this.props.user.userData};
                const userRole = window.localStorage.getItem('userRole');

                window.localStorage.removeItem('userRole');
                this.props.saveUserInfo(userData, userRole);
            }
        }

        return (
            <div className="scrollbar" id="scrollbar-style">
                <div className="row user-settings scrollbar">
                    <div className="avatar">
                        <img src={ photo != '' ? photo : defaultDp } alt="" style={{width: '100px', height: '100px', backgroundColor:'white',position:'relative'}} className="circle relative" />
                        <div className='absolute bottom-0 right-0 bg-gray-500 w-9 h-9 rounded-full camera'>
                            <input type='file' className='hidden' name='profile' id='profile' accept="image/*" onChange={this.handleImageChange}></input>
                            {!this.state.profile_uploading && <label htmlFor='profile'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-camera-fill h-full w-7 mx-auto my-auto text-white hover:cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M10.5 8.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                                    <path d="M2 4a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-1.172a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 9.172 2H6.828a2 2 0 0 0-1.414.586l-.828.828A2 2 0 0 1 3.172 4H2zm.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm9 2.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0z"/>
                                </svg>
                            </label> }
                            {this.state.profile_uploading && <div role="status">
                                <svg aria-hidden="true" className="w-7 h-full mx-auto my-1 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span className="sr-only">Loading...</span>
                            </div>}
                        </div>
                    </div>
                    {this.renderForm(
                        renderField,
                        renderTextArea,
                        onKeyPress,
                        uploadPrescr,
                        userRole,
                        fileName,
                        null,
                        emailVerified
                    )}
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    if (state.auth && state.auth.userData) {
        state.auth.userData.id = state.auth._id
    }

    return {
        user: state.auth,
        initialValues: state.auth ? state.auth.userData : {},
        files: state.files
    };
}

export default connect(mapStateToProps, actions)(reduxForm({
    form: 'userData',
    enableReinitialize: true
})(UserSettings));