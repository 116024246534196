import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from '../actions';
import Lang from '../language/lang.js';

class Landing extends Component {

    render() {
        return (
            <div className="landing-component">
                <div  className="text-container">
                    {/*<div className="text-wrap">
                        <div className="tp-caption largewhitetxt tp-resizeme rs-toggle-content-active">Welcome to Our Site 
                        </div>
                    </div>
                    <div className="text-wrap white">
                        <div className="tp-caption medium_light_white tp-resizeme">We know how to help you
                        </div>
                    </div>
                    <div className="text-wrap white small">
                        <div className="tp-caption medium_light_white tp-resizeme">just login and try
                        </div>
                    </div>
                    <div className="button-wrap">
                        <div className="tp-caption medium_light_white hoverabletp-resizeme">
                            <a href={this.props.auth ? '/' : '/auth/google'}
                                className="waves-effect waves-light btn indigo darken-4">SEE WHAT WE DO</a>
                        </div>
                    </div>*/}
                    <h3><b>{Lang('welcome')}</b></h3>
                    <h4>
                        <b>
                            <p>{Lang('to')}</p>
                            <p>Supium.com</p>
                            <p>{Lang('help')}</p>
                        </b>
                    </h4>

                    <div className="card-container">
                        <div className="row">
                            <div className="col offset-s0 offset-l3 s12 l6">
                              <Link to='/about-us'
                                className="brand-logo">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title"><p>{Lang('visiting')}</p>
                                        {Lang('works')}
                                        </span>
                                    </div>
                                </div>
                              </Link>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="card-container ak-card">
                    <div className="row">
                        <div className="col s12 m4">
                            <Link to='/guest'
                                className="brand-logo">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title">{Lang('guestPage')}</span>
                                        <p>{Lang('textGuestPage')}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col s12 m4">
                            {/* <Link to={this.props.auth ? '/user-settings' : '/patient-registration'}
                                className="brand-logo">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title">{Lang('userRegistration')}</span>
                                        <p>{Lang('textUserRegistration')}</p>
                                    </div>
                                </div>
                            </Link> */}
                            <Link to={'/login-restriction'}
                                className="brand-logo">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title">{Lang('userRegistration')}</span>
                                        <p>{Lang('textUserRegistration')}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col s12 m4">
                            <Link to={this.props.auth ? '/user-settings' : '/advertizer/register'}
                                className="brand-logo">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title">{Lang('advRegistration')}</span>
                                        <p>{Lang('textAdvRegistration')}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return { 
        auth: state.auth,
    };
}

export default connect(mapStateToProps, actions)(Landing);