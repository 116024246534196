import axios from 'axios';
import {
    FETCH_USER, FETCH_COUNTRIES, FETCH_PAYMENT, FETCH_ADVERT, FETCH_PATIENT,
    FETCH_SELECTED_LANGUAGE,
    FETCH_FILES,
    FETCH_ALL_PATIENTS,
    FETCH_USER_ADVS,
    FETCH_ARS_USERS,
    SET_DONATION_DETAILS
} from './types';

export const fetchUser = () => async dispatch => {
    const res = await axios.get('/api/current_user');

    dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleStripes = (token, pendingAmount, isDonation) => async dispatch => {
    token.pendingAmount = pendingAmount;
    token.isDonation = isDonation;

    const res = await axios.post('/api/stripe', token);

    return res.data;
};

export const handlePaypal = (amount, isDonation, isGuest = false) => async dispatch => {
    console.log("passed details : ", amount, isDonation, isGuest);
    let res;
    if (!isGuest) {
        res = await axios.post('/api/paypal', { amount, isDonation });
    } else {
        res = await axios.post('/api/paypal', { amount, isDonation, isGuest });
    }

    if (res.data) {
        // localStorage.setItem('user',JSON.stringify(res.data.user))
        console.log(res.data.url);
        window.location = res.data.url;
    }
};

export const handleSuccessPaypal = data => async dispatch => {
    const res = await axios.post('/api/paypal/success', data);
    if (res.data.user) {
        dispatch({ type: FETCH_USER, payload: res.data.user });
    }

    if (res.data.totalUsers && res.data.amount) {
        dispatch({ type: SET_DONATION_DETAILS, payload: { totalUsers: res.data.totalUsers, amountPerUser: res.data.amount } });
    }
};

export const saveUserInfo = (values, userRole) => async dispatch => {
    const data = {
        additionalInfo: values.additionalInfo,
        address: values.address,
        diagnosis: values.diagnosis,
        dob: values.dob,
        phone: values.phone,
        total: values.total,
        superAdmin: true
    };

    const res = await axios.post('/api/saveUserInfo', { data, userRole });

    dispatch({ type: FETCH_USER, payload: res.data });
};

export const getCountries = () => async dispatch => {
    const res = await axios.get('https://restcountries.com/v2/all');
    let countries = [{
        label: 'All Countries',
        value: 'All Countries',
        id: 'All Countries'
    }];

    res.data.forEach(function (c, i) {
        countries.push({
            label: c.name,
            value: c.name,
            id: c.name
        })
    });

    res.data = countries;

    dispatch({ type: FETCH_COUNTRIES, payload: res.data });
};

export const getLanguage = lang => async dispatch => {
    let location = null;
    let language = null;
    const eng = ['gb', 'usa'];
    const ua = ['ua'];

    if (lang) {
        language = lang;
        localStorage.setItem('language', language);
    } else {
        if (!localStorage.getItem('language')) {
            location = await axios.get('https://pro.ip-api.com/json/?key=VWiozWgU0gWSJ0H');
            language = 'eng';
            if (eng.indexOf(location.data.countryCode.toLowerCase()) !== -1) {
                language = 'eng';
            }
            if (ua.indexOf(location.data.countryCode.toLowerCase()) !== -1) {
                language = 'ua';
            }
            localStorage.setItem('language', language);
        }
    }

    dispatch({ type: FETCH_SELECTED_LANGUAGE, payload: language });
};

export const setPaymentData = values => async dispatch => {
    const res = await axios.post('/api/setPaymentData', values);
    dispatch({ type: FETCH_PAYMENT, payload: res.data });

    const user = await axios.get('/api/current_user');
    dispatch({ type: FETCH_USER, payload: user.data });

    return res.data;
};

export const getAdvs = (data) => async dispatch => {
    const location = await axios.get('https://pro.ip-api.com/json/?key=VWiozWgU0gWSJ0H');
    location.data.pcId = data;
    const res = await axios.post('/api/getAdvs', location.data);

    dispatch({ type: FETCH_ADVERT, payload: res.data });
};

export const currentPatient = values => async dispatch => {
    const res = await axios.post('/api/current_patient', values);

    dispatch({ type: FETCH_PATIENT, payload: res.data });
};

export const getAllPatients = () => async dispatch => {
    const res = await axios.get('/api/allPatients');

    dispatch({ type: FETCH_ALL_PATIENTS, payload: res.data });
};

export const setMoneyData = values => async dispatch => {
    const location = await axios.get('https://pro.ip-api.com/json/?key=VWiozWgU0gWSJ0H');
    values.ip = location.data;
    const res = await axios.post('/api/addMoney', values);
    // manage fetch for all three cases here for guest no need for search fectch patient and viewed by own then fetch user
    if ((res.data) && values.adViewedAs !== 'guest') {
        if (values.adViewedAs == 'user-it-self') {
            dispatch({ type: FETCH_USER, payload: res.data });
        }
        if (values.adViewedAs == 'refrence') {
            dispatch({ type: FETCH_PATIENT, payload: res.data.userData });
        }
    }
};

export const removeFile = (fileName) => async dispatch => {
    const res = await axios.post('/api/removeFile', { name: fileName });

    dispatch({ type: FETCH_FILES, payload: res.data });
};

export const getUploadedFiles = () => async dispatch => {
    const res = await axios.get('/api/getFiles');

    dispatch({ type: FETCH_FILES, payload: res.data });
};

export const getAllAdvs = () => async dispatch => {
    const res = await axios.get('/api/getUserAdvs');

    dispatch({ type: FETCH_USER_ADVS, payload: res.data });
};

export const onBuyMedsClick = (userId) => async dispatch => {
    const res = await axios.post('/api/buyMeds', { userId });

    return res.data;
};

export const getAdvertizers = () => async dispatch => {
    const res = await axios.get('/api/allAdvertizers');

    dispatch({ type: FETCH_ARS_USERS, payload: res.data });
};

/*export const uploadFiles = (file) => async dispatch => {
    
    let data = new FormData();
    data.append('file', file);
    data.append('filename', file.name);

    const file = await axios('/api/upload', {
      method: 'POST',
      body: data,
      file: data,
    }).then((response) => {
        console.log(response);
    });
    //const res = await axios.post('/api/upload', values, config);

    //dispatch({ type: FETCH_PAYMENT, payload: res.data });
};*/
