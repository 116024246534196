import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Lang from '../language/lang.js';

import { Row, Col, CardPanel } from 'react-materialize';
import { Link } from 'react-router-dom';
import axios from 'axios';

class Landing extends Component {

    constructor(props) {
        super(props);
        this.state = {
          message: '',
          form_error: '',
          form_success: '',
          first_name: '',
          last_name: '',
          email: '',
          password: '',
          confirm_password: '',
          terms_check: false
        };
    }
    
    componentDidMount() {
        const queryParams = new URLSearchParams(this.props.location.search);
        const message = queryParams.get('message') || '';
        this.setState({ message });
    }

    setUserRole = (e, userRole) => {
        window.localStorage.setItem('userRole', userRole);
    };

    updateFirstname = (e) => {
        this.setState({
            first_name: e.target.value
        })
    }

    updateLastname = (e) => {
        this.setState({
            last_name: e.target.value
        })
    }

    updateEmail = (e) => {
        this.setState({
            email: e.target.value
        })
    }

    updatePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    updateConfirmPassword = (e) => {
        this.setState({
            confirm_password: e.target.value
        })
    }

    validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
        if (emailRegex.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    updateTermsCheck = () => {
        this.setState({
            terms_check : !this.state.terms_check
        })
    }

    validateForm = () => {
        if(this.state.first_name.length <= 0){
            this.setState({
                form_error: "Firstname can't be empty."
            })
            return false
        }else if(this.state.last_name.length <= 0){
            this.setState({
                form_error: "Lastname can't be empty."
            })
            return false;
        }else if(!this.validateEmail(this.state.email)){
            this.setState({
                form_error: "Enter a valid email address."
            })
            return false
        }else if(this.state.password.length <= 0){
            this.setState({
                form_error: "Password can't be empty."
            })
            return false
        }else if(this.state.password != this.state.confirm_password){
            this.setState({
                form_error: "Password and Confirm password does not match."
            })
            return false
        }else if(!this.state.terms_check){
            this.setState({
                form_error: "Please check terms and condition."
            })
            return false
        }else{
            return true
        }
    }

    registerAdvertizer = (e) => {
        e.preventDefault()
        if(this.validateForm()){
            axios.post('/api/advertizer/register',{first_name:this.state.first_name,last_name:this.state.last_name,email:this.state.email,password:this.state.password,termsCheck:this.state.terms_check}).then((res) => {
                if(res.data.message == 'success'){
                    this.setState({
                        message: '',
                        form_error: '',
                        form_success: 'Registration successful.'
                    })
                }else if(res.data.message == 'error'){
                    this.setState({
                        message: '',
                        form_success: '',
                        form_error: res.data.error_message
                    })
                }
            }).catch((err) => console.log(err))
        }
    }

    registerPatient = (e) => {
        e.preventDefault()
        if(this.validateForm()){
            axios.post('/api/patient/register',{first_name:this.state.first_name,last_name:this.state.last_name,email:this.state.email,password:this.state.password}).then((res) => {
                if(res.data.message == 'success'){
                    this.setState({
                        message: '',
                        form_error: '',
                        form_success: 'Registration successful.'
                    })
                }else if(res.data.message == 'error'){
                    this.setState({
                        message: '',
                        form_success: '',
                        form_error: res.data.error_message
                    })
                }
            }).catch((err) => console.log(err))
        }
    }

    render() {
        //const user = window.location.pathname.indexOf('advertizer') === -1 ? ' User' : 'n Advertizer'
        const { message,form_error,form_success } = this.state;
        return (
            <div className="login-component">
                <div className='row mb-0'>
                    <div className="col offset-s0 offset-l3 s12 l6">
                            {message && <CardPanel className="red">
                                <span className="white-text flex justify-between">
                                    <h1>{decodeURIComponent(message)} <Link to={'/'} className="underline"> Click here to go to home page</Link></h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {this.setState({message: ''})}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </span>
                            </CardPanel>}
                            {form_error && <CardPanel className="red">
                                <span className="white-text flex justify-between">
                                    <h1>{decodeURIComponent(form_error)}</h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {this.setState({form_error: ''})}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </span>
                            </CardPanel>}
                            {form_success && <CardPanel className="green">
                                <span className="white-text flex justify-between">
                                    <h1>{decodeURIComponent(form_success)} <Link to={'/login'} className="underline"> Click here to go to login page</Link></h1>
                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {this.setState({form_success: ''})}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                    </svg>
                                </span>
                            </CardPanel>}
                    </div>
                </div>
                <div className="row">
                    {this.props.match.path === '/advertizer/register' ?
                        <div className="col offset-s0 offset-l3 s12 l6">
                            <div className="card blue-grey darken-1">
                                <div className="card-content white-text">
                                    <span className="card-title center-align">{Lang('RegisterAsAdv')}</span>
                                    <p> </p>
                                </div>
                                <form className='login-form row'>
                                    <div className='w-10/12 flex justify-between mx-auto'>
                                        <div className="w-5/12 text-white">
                                            <label htmlFor="first_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Firstname</label>
                                            <input type='text' name='first_name' onKeyUp={(event) => this.updateFirstname(event)}></input>
                                        </div>
                                        <div className="w-6/12 text-white">
                                            <label htmlFor="last_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Lastname</label>
                                            <input type='text' name='last_name' onKeyUp={(event) => this.updateLastname(event)} ></input>
                                        </div>
                                    </div>
                                    
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type='email' name='email' onKeyUp={(event) => this.updateEmail(event)}></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="password" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Password</label>
                                        <input type='password' name="password" onKeyUp={(event) => this.updatePassword(event)}></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="confirm_password" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                        <input type='password' name="confirm_password" onKeyUp={(event) => this.updateConfirmPassword(event)}></input>
                                    </div>
                                    <label className='flex justify-center mt-2 mb-2'>
                                        <input type="checkbox" onClick={() => this.updateTermsCheck()} className='bg-gray-700 border border-white'/>
                                        <span className='text-white'>I agree with the <Link to="/terms" className="text-blue-500 hover:underline">terms and conditions</Link>.</span>
                                    </label>
                                    <div className=" center-align">
                                        <a className="waves-effect waves-light btn-large" onClick={(event) => this.registerAdvertizer(event)}
                                            >
                                            {Lang('register')}
                                        </a>
                                    </div>
                                </form>
                                <div className="card-action center-align">
                                    <a className="waves-effect waves-light btn-large" 
                                        onClick={(e) => this.setUserRole(e, 'Advertizer')}
                                        href={this.props.auth ? '/user-settings' : '/auth/google?userRole=Advertizer&requestType=register'}>
                                            <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google mr-4" viewBox="0 0 16 16">
                                                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                </svg>
                                                {Lang('registerWith')}
                                            </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        : this.props.match.path === '/patient-registration'?
                        <div className="col offset-s0 offset-l3 s12 l6">
                            <div className="card blue-grey darken-1">
                                <div className="card-content white-text">
                                    <span className="card-title center-align">{Lang('RegisterAsPat')}</span>
                                    <p> </p>
                                </div>
                                <form className='login-form row'>
                                    <div className='w-10/12 flex justify-between mx-auto'>
                                        <div className="w-5/12 text-white">
                                            <label htmlFor="first_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Firstname</label>
                                            <input type='text' name='first_name' onKeyUp={(event) => this.updateFirstname(event)}></input>
                                        </div>
                                        <div className="w-6/12 text-white">
                                            <label htmlFor="last_name" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Lastname</label>
                                            <input type='text' name='last_name' onKeyUp={(event) => this.updateLastname(event)}></input>
                                        </div>
                                    </div>
                                    
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type='email' name='email' onKeyUp={(event) => this.updateEmail(event)}></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="password" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Password</label>
                                        <input type='password' name="password" onKeyUp={(event) => this.updatePassword(event)}></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="confirm_password" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Confirm Password</label>
                                        <input type='password' name="confirm_password" onKeyUp={(event) => this.updateConfirmPassword(event)}></input>
                                    </div>
                                    <label className='flex justify-center mt-2 mb-2'>
                                        <input type="checkbox" onClick={() => this.updateTermsCheck()} className='bg-gray-700 border border-white'/>
                                        <span className='text-white'>I agree with the <Link to="/terms" className="text-blue-500 hover:underline">terms and conditions</Link>.</span>
                                    </label>
                                    <div className=" center-align">
                                        <a className="waves-effect waves-light btn-large"
                                        onClick={(event) => this.registerPatient(event)}
                                            >
                                            {Lang('register')}
                                        </a>
                                    </div>
                                </form>
                                <div className="card-action center-align">
                                    <a className="waves-effect waves-light btn-large"
                                        onClick={(e) => this.setUserRole(e, 'Patient')}
                                        href={this.props.auth ? '/user-settings' : '/auth/google?requestType=register'}>
                                            <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google mr-4" viewBox="0 0 16 16">
                                                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                </svg>
                                                {Lang('registerWith')}
                                            </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="col offset-s0 offset-l3 s12 l6">
                            <div className="card blue-grey darken-1">
                                <div className="card-content white-text">
                                    <span className="card-title center-align">{Lang('login')}</span>
                                    <p> </p>
                                </div>
                                <form className='login-form row' method='post' action='/api/login'>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email</label>
                                        <input type='email' onKeyUp={(event) => this.updateEmail(event)} name='email' id='email'></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-white">
                                        <label htmlFor="password" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Password</label>
                                        <input type='password' name="password" id='password'></input>
                                    </div>
                                    <div className="w-10/12 mx-auto text-gray-300 hover:cursor-pointer">
                                        <Link to="/forgot-password">
                                        Forgot Password?
                                        </Link>
                                    </div>
                                    <div className=" center-align">
                                        <button className="waves-effect waves-light btn-large"
                                            >
                                            {Lang('login')}
                                        </button>
                                    </div>
                                </form>
                                <div className="card-action center-align">
                                    <a className="waves-effect waves-light btn-large"
                                        onClick={(e) => this.setUserRole(e, 'Patient')}
                                        href={this.props.auth ? '/user-settings' : '/auth/google?requestType=login'}>
                                            <div className='flex items-center'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-google mr-4" viewBox="0 0 16 16">
                                                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
                                                </svg>
                                                {Lang('loginWith')}
                                            </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps, actions)(Landing);