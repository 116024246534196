import React,{useState} from "react";
import axios from "axios";

import { Row, Col, CardPanel } from 'react-materialize';
import { Link } from 'react-router-dom';

const ForgotPasswordPage = () => {
    const [message,setMessage] = useState('')
    const [form_error,setFormError] = useState('')
    const [form_success,setFormSuccess] = useState('')
    const [email,setEmail] = useState('')

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
        if (emailRegex.test(email)) {
            return true;
        } else {
            return false;
        }
    }


    const requestForgotPasswordLink = () => {
        if(!validateEmail(email)){
            setFormError('Enter a valid email address before request for password reset link.')
        }else{
            axios.post('/api/forgot-password-request',{email:email}).then((res) => {
                if(res.data.message == 'success'){
                    setMessage('')
                    setFormError('')
                    setFormSuccess('Please check your email for password reset link.')

                }else if(res.data.message == 'error'){
                    setMessage('')
                    setFormSuccess('')
                    setFormError(res.data.error_message)
                }
            }).catch((err) => console.log(err))
        }
    }

    return(
        <div className="login-component">
            <div className='row mb-0'>
                <div className="col offset-s0 offset-l3 s12 l6">
                        {message && <CardPanel className="red">
                            <span className="white-text flex justify-between">
                                <h1>{decodeURIComponent(message)} <Link to={'/'} className="underline"> Click here to go to home page</Link></h1>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {setMessage('')}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>
                            </span>
                        </CardPanel>}
                        {form_error && <CardPanel className="red">
                            <span className="white-text flex justify-between">
                                <h1>{decodeURIComponent(form_error)}</h1>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {setFormError('')}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>
                            </span>
                        </CardPanel>}
                        {form_success && <CardPanel className="green">
                            <span className="white-text flex justify-between">
                                <h1>{decodeURIComponent(form_success)} <Link to={'/login'} className="underline"> Click here to go to login page</Link></h1>
                                <svg xmlns="http://www.w3.org/2000/svg" onClick={() => {setFormSuccess('')}} width="16" height="16" fill="currentColor" className="bi bi-x-lg hover:cursor-pointer" viewBox="0 0 16 16">
                                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                                </svg>
                            </span>
                        </CardPanel>}
                </div>
            </div>
            <div className="row">
                <div className="col offset-s0 offset-l3 s12 l6">
                    <div className="card blue-grey darken-1 pb-4">
                        <div className="card-content white-text">
                            <span className="card-title center-align">Forgot Password</span>
                            <p> </p>
                        </div>
                        <div>
                            <div className="w-10/12 mx-auto text-white">
                                <label htmlFor="email" className="block mb-2 text-md font-medium text-gray-900 dark:text-white">Email</label>
                                <input type='email' onKeyUp={(event) => setEmail(event.target.value)} name='email' id='email'></input>
                            </div>
                            <div className="mt-2 center-align">
                                <button className="waves-effect waves-light btn-large" onClick={() => requestForgotPasswordLink()}
                                    >
                                    Request
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPasswordPage