import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import Lang from '../language/lang.js';

class Guest extends Component {
    componentWillMount() {
        this.props.currentPatient({userId: '5bf6ae1dc590680015156c18'});
    }

    componentWillUnmount() {
        this.props.currentPatient({userId: ''});
    }

    render() {
        return (
            <div className="guest-component">
                <div className="card-container">
                    <div className="row">
                        <div className="col s12 offset-m2 m8">
                            <div className="card blue hoverable darken-1">
                                <div className="card-content white-text funds">
                                    <span className="card-title center-align ">{Lang('guestFunds')} <b>{this.props.patient ? '$'+this.props.patient.received : '$0'}</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 offset-m2 m4">
                            <Link to='/advView/5bf6ae1dc590680015156c18'
                                className="button-item">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title center-align">{Lang('advertisements')}</span>
                                        <p>{Lang('textAdv')}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/*<div className="col s12 m3">
                            <div className="card blue hoverable darken-1">
                                <div className="card-content white-text">
                                    <span className="card-title">Surveys</span>
                                    <p>Register as a patient to get funds for your theatment</p>
                                </div>
                                <div className="card-action">
                                    <Link
                                        to='/surveys'
                                        className="button-item">
                                        Go to Surveys
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 m3">
                            <div className="card blue hoverable darken-1">
                                <div className="card-content white-text">
                                    <span className="card-title">Paid Games</span>
                                    <p>Place your adverts to earn money here! We are waiting for you</p>
                                </div>
                                <div className="card-action">
                                    <Link
                                        to='/advertisement'
                                        className="button-item">
                                        Want to play?
                                    </Link>
                                </div>
                            </div>
                        </div>*/}
                        <div className="col s12 m4">
                            <Link to='/donation'
                                className="button-item">
                                <div className="card blue hoverable darken-1">
                                    <div className="card-content white-text">
                                        <span className="card-title center-align">{Lang('donations')}</span>
                                        <p>{Lang('textDonations')}
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        patient: state.patient,
    };
}

export default connect(mapStateToProps, actions)(Guest);