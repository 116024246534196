import React from 'react';

const Landing = () => {
    let ln = localStorage.getItem('language') || global.lang || 'eng';
    console.log(ln)
    return (
        <div className='mb-10'>
            {ln == 'eng' ? 
            <p class="my-4 w-10/12 mx-auto bg-gray-200 rounded-md p-4">
                <h1 class="text-2xl">Terms and conditions</h1>

                <ol class="mt-4">
                    <li><span className='font-bold'>Acceptance of Terms:</span> By accessing and using supium.com (the "Website"), you hereby agree to be bound by these terms and conditions. If you do not agree with any part of these terms, please refrain from using the Website.</li>
                    <li><span className='font-bold'>Intellectual Property Rights:</span> All content on the Website, including but not limited to text, images, graphics, trademarks, and logos, are the intellectual property of Supium Ltd or its licensors. Unauthorized use of any material on the Website may violate intellectual property laws and may result in legal action. </li>
                    <li><span className='font-bold'>User Eligibility:</span> You must be at least 18 years old to use the Website. By using the Website, you represent and warrant that you are of legal age to enter into this agreement and comply with all applicable laws and regulations. </li>
                    <li><span className='font-bold'>Advertisements and User Earnings:</span> The Website allows users to earn funds by clicking on advertisements. Users can click on one advertisement per day, and the same advertisement will be active again after 24 hours. When guests visit the website through the guest page and watch advertisements, the cost they earn is equally divided among all registered users. Donations made through the guest page are also equally divided among all registered users and added to their accounts. Users are solely responsible for their earnings and any tax obligations arising from them. </li>
                    <li><span className='font-bold'>Compliance with Laws:</span> Users and advertisers must comply with all applicable laws and regulations, including but not limited to online advertising rules in your country. Supium Ltd is not responsible for any illegal activities conducted by users or advertisers on the Website. </li>
                    <li><span className='font-bold'>Prohibited Products and Services:</span> The following products and services CANNOT be advertised on the Website: 
                        <ol className='ml-10 list-disc'>
                            <li>Illegal Sales </li>
                            <li>Magnetic gas savers; hydrogen-generator gas savers, etc. </li>
                            <li>Orgone generators </li>
                            <li>Music, movie, or video sharing sites where unauthorized copies are available. </li>
                            <li>Make money online and get rich schemes</li>
                            <li>Vendors with any website that displays unauthorized trademarks or logos. </li>
                            <li>Unauthorized copies of books, e-books, or manuals.</li>

                            <li>Unauthorized (pirated) copies of music recordings or movies.</li>

                            <li>Reproductions of paintings by artists still protected by copyright.</li>

                            <li>Websites that offer investment or any kind of profit-making unless the business is verified.</li>

                            <li>Vendors with any website with a domain name that infringes on a trademark or trade name.</li>

                            <li>Unauthorized copies of electronic products, software, arcade games, console games, etc.</li>

                            <li>Website templates that include unauthorized copies of images, photographs, or graphic files.</li>

                            <li>Pornography and Adult Content</li>

                            <li>Tasers, Stun Guns, Air Guns, Paint Guns, Weapons, Swords, and Knives</li>

                            <li>Fireworks or Pyrotechnic Devices or Supplies</li>

                            <li>Drug paraphernalia of any kind, including but not limited to:</li>
                                <ol className='ml-10 list-disc'>
                                    <li>Drug pipes </li>

                                    <li>Bongs </li>

                                    <li>"Vaporizers" </li>

                                    <li>Marijuana growth enhancement products </li>

                                    <li>Mushroom grow kits </li>

                                    <li>Spores </li>

                                    <li>"Street Drug Alternatives" </li>

                                    <li>Rolling papers or rolling devices </li>

                                    <li>Roach clips </li>

                                    <li>Drug concealment devices </li>

                                    <li>Scales for weighing drugs </li>

                                    <li>Syringes </li>
                                </ol>
                            <li>Live Animals </li>

                            <li>Hazardous Materials, Combustibles, Corrosives </li>

                            <li>IP Advertising </li>

                            <li>Programs or services designed to harvest e-mail addresses or other personal information from the Internet </li>

                            <li>Services or software to post messages on multiple forums, message boards, bulletin boards, newsgroups, etc. </li>

                            <li>Brands that will be rejected without documented proof of authenticity and licensing from the manufacturers include (but are not limited to): Abercrombie & Fitch; Bacccarat; BAPE; DCBG; Bottega Veneta; Burberry; Cartier; Chanel; Jimmy Choo; Chloe; Celine; Coach; Juicy Couture; Diesel; Dior; Dolce & Gabbana; DKNY; Fendi; Isabella Fiore; FUBU; Jean Paul Gaultier ; Gucci; Hermes; Marc Jacobs; Anne Klein; KORS; Lacroix; Louis Vuitton; Miu Miu; Nike; Polo; Emilio Pucci; Puma; Prada; Ralph Lauren; Nina Ricci; Ray-Ban; Rolex; Tommy Hilfiger; Versace; Victoria's Secret; Yves St Laurent. </li>
                        </ol>
                    </li>
                    <li><span className='font-bold'>Limitation of Liability:</span> Supium Ltd strives to provide accurate and up-to-date information on the Website but makes no warranties or representations regarding the accuracy, reliability, or availability of the content. The use of the Website is at your own risk, and Supium Ltd shall not be liable for any damages or losses arising from the use of or inability to use the Website. </li>

                    <li><span className='font-bold'>Termination:</span> Supium Ltd reserves the right to terminate or suspend access to the Website at any time and for any reason without prior notice. These terms and conditions will remain in effect even after termination. </li>

                    <li><span className='font-bold'>Governing Law and Jurisdiction:</span> These terms and conditions shall be governed by the laws of the respective countries, considering the national rules on online advertising in your country. Any disputes arising from or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts in the respective countries. </li>

                    <li><span className='font-bold'>Privacy Policy Reference:</span> By using the Website, you also agree to the terms of our Privacy Policy, which outlines how we collect, use, and protect your personal information. </li>

                    <li><span className='font-bold'>Modification of Terms:</span> Supium Ltd reserves the right to modify these terms and conditions at any time. Users will be notified of any changes, and continued use of the Website after modifications implies acceptance of the new terms. </li>

                    <li><span className='font-bold'>Disclaimer of Endorsement:</span> The appearance of advertisements on the Website does not constitute endorsement or recommendation by Supium Ltd. Users should exercise their own judgment when interacting with advertisements. </li>

                    <li><span className='font-bold'>User Conduct:</span> Users are prohibited from engaging in fraudulent activity, attempting to manipulate earnings, or violating the rights of others while using the Website. </li>

                    <li><span className='font-bold'>Indemnification:</span> By using the Website, you agree to indemnify and hold Supium Ltd harmless from any claims, losses, or liabilities arising from your use of the Website or violation of these terms and conditions. </li>

                    <li><span className='font-bold'>Communication Preferences:</span> You can manage your communication preferences by adjusting settings in your account. However, you may still receive important notifications related to your account or the Website. </li>

                    <li><span className='font-bold'>Accessibility Statement:</span> We are committed to ensuring accessibility for all users. If you encounter any accessibility barriers on the Website, please contact us at [contact email/phone] to report the issue. </li>

                    <li><span className='font-bold'>Force Majeure:</span> Supium Ltd will not be liable for any failure or delay in performance due to circumstances beyond its reasonable control, such as natural disasters or governmental actions. </li>

                    <li><span className='font-bold'>Severability:</span> If any part of these terms and conditions is found to be invalid or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law. </li>

                    <li><span className='font-bold'>Contact Information:</span> For questions, concerns, or feedback regarding these terms and conditions or the Website, please contact us at info@supium.com </li>
                </ol>
            </p>
            : 
            <p class="my-4 w-10/12 mx-auto bg-gray-200 rounded-md p-4">
                <h1 class="text-2xl">Умови та положення</h1>

                <ol class="mt-4">
                    <li><span className='font-bold'>Прийняття умов:</span> Заходячи та використовуючи supium.com (далі «Веб-сайт»), ви тим самим погоджуєтеся бути зв'язаними цими умовами та положеннями. Якщо ви не погоджуєтесь з будь-якою частиною цих умов, будь ласка, утримайтеся від використання Веб-сайту. </li>
                    <li><span className='font-bold'>Права на інтелектуальну власність:</span> Весь вміст на Веб-сайті, включаючи, але не обмежуючись текстом, зображеннями, графікою, товарними знаками та логотипами, є інтелектуальною власністю Supium Ltd або її ліцензіарів. Несанкціоноване використання будь-якого матеріалу на Веб-сайті може порушити закони про інтелектуальну власність та призвести до правових дій. </li>
                    <li><span className='font-bold'>Право на користування:</span> Ви повинні бути щонайменше 18 років, щоб користуватися Веб-сайтом. Використовуючи Веб-сайт, ви підтверджуєте та гарантуєте, що маєте законний вік для укладення цієї угоди та виконання всіх відповідних законів та правил.  </li>
                    <li><span className='font-bold'>Реклама та заробіток користувачів</span> Веб-сайт дозволяє користувачам заробляти кошти, натискаючи на рекламу. Користувачі можуть натискати на одну рекламу на день, і та сама реклама буде активною знову через 24 години. Коли гості відвідують веб-сайт через сторінку гостя та дивляться реклами, вартість, яку вони заробляють, рівномірно розподіляється між усіма зареєстрованими користувачами. Пожертви, зроблені через сторінку гостя, також рівномірно розподіляються між усіма зареєстрованими користувачами та додаються до їх облікових записів. Користувачі несуть виключну відповідальність за свій заробіток та будь-які податкові зобов'язання, що виникають з нього. </li>
                    <li><span className='font-bold'>Дотримання законів:</span> Користувачі та рекламодавці повинні дотримуватися всіх відповідних законів та правил, включаючи, але не обмежуючись, правилами онлайн-реклами у вашій країні. Supium Ltd не несе відповідальності за будь-які незаконні дії, здійснені користувачами або рекламодавцями на Веб-сайті.  </li>
                    <li><span className='font-bold'>Заборонені товари та послуги:</span> На веб-сайті НЕ можна рекламувати наступні товари та послуги: 
                        <ol className='ml-10 list-disc'>
                            <li>Незаконні продажі  </li>
                            <li>Магнітні економізатори газу; економізатори газу на основі водню тощо. </li>
                            <li>Генератори оргону </li>
                            <li>Сайти для обміну музикою, фільмами або відео, де доступні несанкціоновані копії.  </li>
                            <li>Заробіток в Інтернеті та схеми збагачення  </li>
                            <li>Продавці з будь-яким веб-сайтом, що відображає несанкціоновані товарні знаки або логотипи. </li>
                            <li>Несанкціоновані копії книг, електронних книг або посібників.  </li>

                            <li>Unauthorized (pirated) copies of music recordings or movies.</li>

                            <li>Несанкціоновані (піратські) копії музичних записів або фільмів. </li>

                            <li>Репродукції картин художників, ще захищених авторським правом. </li>

                            <li>Веб-сайти, які пропонують інвестиції або будь-який вид прибутку, якщо бізнес не підтверджено.  </li>

                            <li>Продавці з будь-яким веб-сайтом, доменне ім'я якого порушує товарний знак або комерційне ім'я. </li>

                            <li>Несанкціоновані копії електронних товарів, програмного забезпечення, аркадних ігор, консольних ігор тощо.</li>

                            <li>Порнографія та контент для дорослих </li>

                            <li>Тазери, електрошокові пристрої, пневматичні зброї, фарбувальні пістолети, зброя, мечі та ножі </li>

                            <li> Феєрверки або піротехнічні пристрої чи матеріали </li>

                            <li> Приналежності для наркотиків будь-якого виду, включаючи, але не обмежуючись: </li>
                                <ol className='ml-10 list-disc'>
                                    <li>Трубки для наркотиків </li>

                                    <li>Бонги  </li>

                                    <li>"Вапорайзери"  </li>

                                    <li>Продукти для підвищення врожайності конопель</li>

                                    <li>Набори для вирощування грибів  </li>

                                    <li>Спори • "Альтернативи наркотикам з вулиці" </li>

                                    <li>Папір для куріння або пристрої для куріння</li>

                                    <li> Зажими для згортання   </li>

                                    <li> Приховувальні пристрої для наркотиків   </li>

                                    <li>Ваги для зважування наркотиків  </li>

                                    <li>Шприци  </li>

                                    <li> Живі тварини  </li>
                                </ol>
                            <li>Небезпечні матеріали, горючі речовини, корозійні речовини</li>

                            <li> Реклама IP  </li>

                            <li>Програми або послуги, призначені для збору електронних адрес або іншої особистої інформації з Інтернету </li>

                            <li>Послуги або програмне забезпечення для публікації повідомлень на кількох форумах, дошках оголошень, дошках об'яв, новинах тощо.  </li>

                            <li>Бренди, які будуть відхилені без підтвердження автентичності та ліцензування від виробників включають (але не обмежуються):</li>

                        </ol>
                    </li>
                    <li><span className='font-bold'>Обмеження відповідальності:</span> Supium Ltd прагне забезпечити точну та актуальну інформацію на Веб-сайті, але не надає жодних гарантій або заяв щодо точності, надійності чи доступності вмісту. Використання Веб-сайту відбувається на ваш власний ризик, і Supium Ltd не буде нести відповідальність за будь-які збитки або втрати, що виникають з використання або неможливості використання Веб-сайту. </li>

                    <li><span className='font-bold'>Припинення:</span> Supium Ltd залишає за собою право припинити або призупинити доступ до Веб-сайту у будь-який час і з будь-яких причин без попереднього повідомлення. Ці умови та положення залишаються чинними навіть після припинення. </li>

                    <li><span className='font-bold'>Закон та юрисдикція:</span> Ці умови та положення підлягають законам відповідних країн, враховуючи національні правила щодо онлайн-реклами у вашій країні. Будь-які спори, що виникають з або у зв'язку з цими умовами та положеннями, підлягають виключній юрисдикції судів відповідних країн.  </li>

                    <li><span className='font-bold'>Посилання на Політику конфіденційності:</span> Використовуючи Веб-сайт, ви також погоджуєтеся з умовами нашої Політики конфіденційності, яка описує, як ми збираємо, використовуємо та захищаємо вашу особисту інформацію.  </li>

                    <li><span className='font-bold'>Зміни умов:</span> Supium Ltd залишає за собою право вносити зміни в ці умови та положення в будь-який час. Користувачі будуть повідомлені про будь-які зміни, а продовження використання Веб-сайту після модифікацій підтверджує прийняття нових умов.  </li>

                    <li><span className='font-bold'>Відмова від підтримки:</span>  Вигляд реклами на Веб-сайті не означає підтримки або рекомендації від Supium Ltd. Користувачі повинні використовувати власний суд при взаємодії з рекламою. </li>

                    <li><span className='font-bold'>Поведінка користувача:</span> Користувачам заборонено займатися шахрайством, намагатися маніпулювати заробітками або порушувати права інших під час використання Веб-сайту.  </li>

                    <li><span className='font-bold'>Відшкодування:</span> Використовуючи Веб-сайт, ви погоджуєтеся відшкодувати Supium Ltd від будь-яких претензій, збитків або зобов'язань, що виникають з використання Веб-сайту або порушення цих умов та положень.  </li>

                    <li><span className='font-bold'>Вибір комунікацій: </span> Ви можете керувати своїми налаштуваннями комунікації, налаштовуючи параметри свого облікового запису. Однак ви все ще можете отримувати важливі сповіщення, що стосуються вашого облікового запису або Веб-сайту.  </li>

                    <li><span className='font-bold'>Заява про доступність: </span> Ми зобов'язані забезпечити доступність для всіх користувачів. Якщо ви зіткнулися з будь-якими перешкодами в доступності на Веб-сайті, будь ласка, зв'яжіться з нами за адресою [контактний email/телефон], щоб повідомити про проблему.  </li>

                    <li><span className='font-bold'>Форс-мажор: </span> Supium Ltd не несе відповідальності за будь-яку невиконання або затримку виконання через обставини, які перебувають поза її контролем, такі як природні лиха або дії уряду.  </li>

                    <li><span className='font-bold'>Розрив зв'язку:</span>  Якщо будь-яка частина цих умов та положень виявиться недійсною або невиконуваною, решта положень залишиться чинними та виконуваними в повному обсязі в міру дозволу закону.  </li>

                    <li><span className='font-bold'>Контактна інформація:</span> Якщо у вас є питання, зауваження чи відгуки щодо цих умов та положень або Веб-сайту, будь ласка, звертайтеся до нас за адресою info@supium.com. </li>
                </ol>
            </p>
            }
            
            
        </div>
    );
};

export default Landing;