import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import countriesReducer from './countriesReducer';
import paymentReducer from './paymentReducer';
import advReducer from './advReducer';
import currentPatient from './currentPatient';
import allPatientsReducer from './allPatientsReducer';
import allUserAdvsReducer from './allUserAdvsReducer';
import fileUpload from './fileUpload';
import allAdvsReducer from './allAdvsReducer';
import selectedLanguageReducer from './selectedLanguageReducer';
import donationDetailsReducer from './donationDetailsReducer';

export default combineReducers({
    auth: authReducer,
    countries: countriesReducer,
    form: reduxForm,
    payment: paymentReducer,
    advs: advReducer,
    selectedLanguage: selectedLanguageReducer,
    patient: currentPatient,
    allPatients: allPatientsReducer,
    allUserAdvs: allUserAdvsReducer,
    files: fileUpload,
    allAdvertizers: allAdvsReducer,
    donationDetails: donationDetailsReducer
});