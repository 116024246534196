import {SET_DONATION_DETAILS } from '../actions/types';

export default function(state = null, action) {
    // console.log(action);
    switch(action.type) {
        case SET_DONATION_DETAILS:
            return {
                ...state,
                details: action.payload
            };
        default:
            return state;
    }
}