import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import AdBlockDetect from 'react-ad-block-detect';
import '../asset/css/main.css';

import Header from './Header';
import Landing from './Landing';
import Login from './Login';
import Footer from './Footer';
import Guest from './Guest';
import Terms from './Terms';
import UserSettings from './UserSettings';
import Dashboard from './Dashboard';
import AdvList from './AdvList';
import AdvView from './AdvView';
import Payments from './Payments';
import PatientPage from './PatientPage';
import PaypalPage from './PaypalPage';
import StripePage from './StripePage';
import AboutUs from './AboutUs';
import AdminPanel from './AdminPanel';
import AdvPanel from './AdvPanel';
import AdvertizersPage from './AdvertizersPage';
import LoginRestrictionPage from './LoginRestrictionPage';
import TokenRequests from './TokenRequests';
import ResetPasswordPage from './ResetPassword'
import ForgotPasswordPage from './ForgotPasswordPage'
//import PopUp from '../helpers/PopUp';
import PrivacyPolicyPage from './PrivacyPolicyPage'

function IsBlockerDetected(props) {
  global.adDetected = true;

  return (
    <div className="disable-blocker">
      <p>Please disable adblock</p>
    </div>
  )
}

class App extends Component {
  componentDidMount() {
    this.props.fetchUser();

    if (!this.props.selectedLanguage) {
      this.props.getLanguage();
    }
  }

  state = {
    refresh: false
  }

  refreshPage = () => {
    if (global.adDetected && this.state.refresh) {
      return;
    }

    if (!this.state.refresh) {
      this.setState({refresh: true});
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Route>
          <div>
            <AdBlockDetect>
              <IsBlockerDetected />
              { this.refreshPage() }
            </AdBlockDetect>
            {!global.adDetected && 
              <div className="container">
                <Header />
                <div>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/advertizer/register" component={Login} />
                  <Route exact path="/advList/" component={AdvList} />
                  <Route exact path="/guest" component={Guest} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/payments" component={Payments} />
                  <Route exact path="/donation" component={Payments} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/about-us" component={AboutUs} />
                  <Route exact path="/user-settings" component={UserSettings} />
                  <Route exact path="/admin-panel" component={AdminPanel} />
                  <Route exact path="/adv-panel" component={AdvPanel} />
                  <Route exact path="/advertizers-page" component={AdvertizersPage} />
                  {/* new route for login restriction page  */}
                  <Route exact path="/login-restriction" component={LoginRestrictionPage} /> 
                  {/* new route for user registration*/}
                  <Route exact path="/patient-registration" component={Login} />
                  {/* new route for tokens*/}
                  <Route exact path="/token-requests" component={TokenRequests} />
                  {/* new route for forgot password */}
                  <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                  {/* new route for reset password */}
                  <Route exact path="/reset-password" component={ResetPasswordPage} />

                  <Route exact path="/patient/:patientId" component={PatientPage} />
                  <Route exact path="/advView/:patientId" component={AdvView} />
                  <Route exact path="/paypal/:status" component={PaypalPage} />
                  <Route exact path="/stripe" component={StripePage} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />


                </div>
                <Footer />
              </div>
            }
          </div>
        </Route>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  global.lang = state.selectedLanguage;

  return {
    selectedLanguage: state.selectedLanguage
  };
}

export default connect(mapStateToProps, actions)(App);