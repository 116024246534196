import { FETCH_ALL_PATIENTS } from '../actions/types';

export default function(state = null, action) {
    //console.log(action);
    switch(action.type) {
        case FETCH_ALL_PATIENTS:
            return (action.payload || false);
        default:
            return state;
    }
}