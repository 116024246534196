import { FETCH_FILES } from '../actions/types';

export default function(state = null, action) {
    //console.log(action);
    switch(action.type) {
        case FETCH_FILES:
            return (action.payload || false);
        default:
            return state;
    }
}