import { FETCH_PAYMENT } from '../actions/types';

export default function(state = null, action) {
    //console.log(action);
    switch(action.type) {
        case FETCH_PAYMENT:
            return (action.payload || false);
        default:
            return state;
    }
}