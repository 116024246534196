import React from 'react';

const Landing = () => {
    let ln = localStorage.getItem('language') || global.lang || 'eng';
    console.log(ln)
    return (
        <div className='mb-10'>
            {ln == 'eng' ? 
            <p class="my-4 w-10/12 mx-auto bg-gray-200 rounded-md p-4">
                <h1 class="text-2xl">Privacy Policy</h1>

                <p class="mt-4">At Supium Ltd, we are committed to ensuring the privacy and security of both our users and advertisers. Our platform aims to facilitate a seamless and transparent experience for all parties involved. Below, we outline our practices regarding the collection, usage, and sharing of information: </p>
                
                <p class="mt-2 font-bold">Information Collection at Supium.com</p> 

                <p>As an online advertising platform, Supium.com serves both advertisers and users, allowing users to earn funds for their treatment while enabling advertisers to reach their target audience. Advertisers may provide details such as contact information, advertising preferences, and payment details to facilitate advertising campaigns. For users, the platform offers the opportunity to connect with friends for support. Upon registration, users receive a unique link and registration number and can leave information about their needs, the required sum, and relevant medical details.</p>
                
                <p class="mt-2 font-bold">Utilization of Information</p>

                <p>Information gathered from advertisers is used to tailor advertising campaigns and measure ad performance. Advertisers may receive reports and analytics to aid in decision-making. For users, payments are received for every click on advertisements based on different options: Petit, XS, S, M, L, and XL, each with varying costs per click. The accumulated funds can be withdrawn through PayPal once the required sum is collected.</p>
           
                <p class="mt-2 font-bold">Data Sharing Practices</p>

                <p>We are committed to safeguarding the information of our users and advertisers and do not share data with external parties except in specific circumstances, including legal or safety requirements. Additionally, data shared publicly, such as advertisements, may be beyond our control, and users and advertisers are encouraged to review the privacy policies of third-party services before engaging with them.</p>

                <p class="mt-2 font-bold">Policy Updates and Queries</p>

                <p>Supium Ltd reserves the right to update this privacy policy periodically. For any inquiries or concerns regarding our privacy practices, please contact us at info@supium.com.</p>
           
                <p class="mt-2">By utilizing Supium.com, advertisers and users agree to comply with the terms outlined in this privacy policy. We are dedicated to maintaining the confidentiality and security of all information shared on our platform, fostering a trustworthy and compliant advertising environment for all parties involved. </p>
            </p>
            : 
            <p class="my-4 w-10/12 mx-auto bg-gray-200 rounded-md p-4">
                <h1 class="text-2xl">Політика конфіденційності</h1>

                <p class="mt-4">У компанії Supium Ltd ми прагнемо забезпечити конфіденційність та безпеку як наших користувачів, так і рекламодавців. Наша платформа спрямована на забезпечення неперервного та прозорого досвіду для всіх сторін. Нижче ми розглядаємо наші практики щодо збору, використання та обміну інформацією: </p>
                
                <p class="mt-2 font-bold">Збір інформації на Supium.com</p> 

                <p>Як онлайн-платформа реклами, Supium.com обслуговує як рекламодавців, так і користувачів, дозволяючи користувачам отримувати кошти на їхнє лікування, а рекламодавцям - досягати своєї цільової аудиторії. Рекламодавці можуть надавати деталі, такі як контактна інформація, вподобання в рекламі та реквізити оплати для здійснення рекламних кампаній. Для користувачів платформа пропонує можливість зв'язку з друзями для підтримки. Після реєстрації користувачі отримують унікальне посилання та номер реєстрації та можуть залишати інформацію про свої потреби, необхідну суму та відповідні медичні деталі.</p>
                
                <p class="mt-2 font-bold">Використання інформації</p>

                <p>Інформація, зібрана від рекламодавців, використовується для налаштування рекламних кампаній та вимірювання результативності реклами. Рекламодавці можуть отримувати звіти та аналітику для допомоги в прийнятті рішень. Для користувачів платяться кошти за кожен клік на рекламу залежно від різних варіантів: Petit, XS, S, M, L, та XL, кожен з власними вартостями за клік. Накопичені кошти можуть бути виведені через PayPal, як тільки необхідна сума буде зібрана. </p>
           
                <p class="mt-2 font-bold">Практика обміну даними </p>

                <p>Ми прагнемо захищати інформацію наших користувачів і рекламодавців і не передаємо дані зовнішнім сторонам, за винятком особливих обставин, зокрема вимог законодавства чи безпеки. Крім того, публічні дані, такі як реклама, можуть бути поза нашим контролем, тому користувачам і рекламодавцям рекомендується ознайомитися з політикою конфіденційності сторонніх служб, перш ніж взаємодіяти з ними. </p>

                <p class="mt-2 font-bold">Оновлення політики та запити </p>

                <p>Supium Ltd залишає за собою право періодично оновлювати цю політику конфіденційності. З будь-якими запитами або проблемами щодо нашої політики конфіденційності, будь ласка, зв’яжіться з нами за адресою info@supium.com. </p>
           
                <p class="mt-2">Використовуючи Supium.com, рекламодавці та користувачі погоджуються дотримуватися умов, викладених у цій політиці конфіденційності. Ми прагнемо підтримувати конфіденційність і безпеку всієї інформації, опублікованої на нашій платформі, сприяючи створенню надійного та відповідного рекламного середовища для всіх залучених сторін.</p>
            </p>
            }
            
            
        </div>
    );
};

export default Landing;