import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import * as actions from '../actions';
import ADVERTS from '../helpers/advs';
import Lang from '../language/lang.js';

class Ads extends Component {
    state = {
      adverts: ADVERTS,
      payment: false,
      showPopup: false,
      controls: {
        countries: []
      }
    };

    componentWillMount() {
      this.props.getCountries();
    };

    onBuyClick = (e, item) => {
      e.preventDefault();
      const { balance } = {...this.props.auth};

      if (balance > item.optionSelected.value) {
        let controls = {
          item: item.title,
          price: item.optionSelected.label,
          clicks: item.optionSelected.clicks,
          earn: item.ptc,
          amount: item.optionSelected.value,
          balance
        };

        this.setState({ payment: true, controls, paymentData: item });
       } else {
        setTimeout(function(){
          this.setState({ showPopup: false });
        }.bind(this),5000);

        this.setState({ showPopup: true });
       }
    };

    handlePriceChange = (val, i) => {
      const { adverts } = this.state;

      adverts[i].optionSelected = val;

      this.setState({ adverts });
    };

    handleCountryChange = (val) => {
      const { controls } = this.state;

      controls.countries = val;

      this.setState({ controls });
    };

    onInputChanged = (e) => {
      let { value, name, checked } = e.currentTarget;
      let { controls } = this.state;

      if (name === 'terms') {
        controls[name] = checked;
      } else {
        controls[name] = value;
      }

      this.setState({ controls });
    };

    submitForm = async () => {
      let data = await this.props.setPaymentData(this.state.controls);

      if (data && data.success) {
        this.props.history.push('/dashboard');
      } else {
        setTimeout(function(){
          this.setState({ showPopup: false });
        }.bind(this),5000);

        this.setState({ showPopup: true });
      }
    };

    renderPayment = () => {
        const isCountriesSelected = this.state.controls.countries && this.state.controls.countries.length !== 0;
        const funds = this.props.auth.balance >= this.state.paymentData.optionSelected.value;
        const {controls} = this.state;

        return (
            <div className="col s6 offset-s3">
                <div className="row">
                    <div className="input-field col s6">
                        <input name="item" onChange={(e) => this.onInputChanged(e)} value={controls.item} type="text" className="validate" disabled/>
                        <label htmlFor="item" className="active">{Lang('item')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input name="price" onChange={(e) => this.onInputChanged(e)} value={controls.price} type="text" className="validate" disabled/>
                        <label htmlFor="price" className="active">{Lang('price')}</label>
                    </div>
                    <div className="input-field col s12">
                        <input name="balance" onChange={(e) => this.onInputChanged(e)} value={controls.balance} type="text" className="validate" disabled/>
                        <label htmlFor="balance" className="active">{Lang('balance')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input name="title" onChange={(e) => this.onInputChanged(e)} value={controls.title} type="text" className="validate" required />
                        <label htmlFor="title" className="active">{Lang('title')}</label>
                    </div>
                    <div className="input-field col s6">
                        <input name="subtitle" onChange={(e) => this.onInputChanged(e)} value={controls.subtitle} type="text" className="validate" required />
                        <label htmlFor="subtitle" className="active">{Lang('subtitle')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <input name="targetUrl" onChange={(e) => this.onInputChanged(e)} value={controls.targetUrl} type="text" className="validate" required />
                        <label htmlFor="targetUrl" className="active">{Lang('targetUrl')}</label>
                    </div>
                    <div className="input-field col s12">
                        <input name="previewUrl" onChange={(e) => this.onInputChanged(e)} value={controls.previewUrl} type="text" className="validate" required />
                        <label htmlFor="previewUrl" className="active">{Lang('previewUrl')}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s12">
                        <Select
                            menuPlacement="top"
                            value={ this.state.controls.countries }
                            onChange={(values) => this.handleCountryChange(values) }
                            options={ this.props.countries }
                            isMulti={ true }
                            className="dropdown-select"
                            classNamePrefix="dropdown-inner"
                            isSearchable={ true }
                            closeMenuOnSelect={ false }
                        />
                        <label htmlFor="countries" className="active">{Lang('countries')}</label>
                    </div>
                    <div className="input-field col s12">
                      <label>
                        <input name="terms" onChange={(e) => this.onInputChanged(e)} value={controls.terms} type="checkbox" className="validate filled-in" required />
                        <span>{Lang('terms')}</span>
                      </label>
                    </div>
                </div>
                <button type="submit"
                    onClick={this.submitForm}
                    disabled={ !isCountriesSelected || !funds }
                    className="waves-effect waves-light right col s3 btn-small">{Lang('buyNow')}
                </button>
            </div>
        )
    };
    
    renderAdsContent = () => {
      const { adverts } = this.state;

      const list = adverts.map((item, i) =>
        <div key={item.title} className="col s12 m6 l2 m2">
            <div className="card">
                <div className="card-image">
                    <img src="https://thelastmixedtapedotcom.files.wordpress.com/2017/08/picture-this.jpg" alt="" />
                    <span className="card-title">{item.title}</span>
                    <button disabled={!item.optionSelected} className="btn-floating halfway-fab btn waves-effect waves-light buy-button"
                        onClick={(e) => this.onBuyClick(e, item)} name="action">{Lang('buy')}
                        <i className="material-icons right">send</i>
                    </button>
                </div>
                <div className="card-content">
                    <div className="input-field browser-default">
                        <Select
                            value={ item.optionSelected }
                            onChange={(values) => this.handlePriceChange(values, i) }
                            options={ item.dropdown }
                            isSearchable={false}
                            className="dropdown-price validate"
                            classNamePrefix="dropdown-inner-price"
                            closeMenuOnSelect={ true }
                        />
                    </div>
                </div>
            </div>
        </div>
      );
      return list;
    };

    render() {
      const { payment } = this.state;

      return (
        <div className="ads-component scrollbar" id="scrollbar-style">
          { this.state.showPopup && 
            <div className="alert alert-warning">
              <strong>Error!</strong> {Lang('insfFunds')}
            </div>
          }
          <div className="row container-ads">
            {payment ? this.renderPayment() : this.renderAdsContent()}
          </div>
        </div>
      )
    }
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        countries: state.countries,
        paymentData: state.form.paymentData ? state.form.paymentData.values : {}
    };
}

export default connect(mapStateToProps, actions)(Ads);