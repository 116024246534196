import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import '../asset/css/main.css';
import Lang from '../language/lang.js';
import US from '../asset/icons/eng.png';
import UA from '../asset/icons/ua.png';

class Footer extends Component {
    render() {
        return (
            <footer className="page-footer blue darken-1 top-radius">
                <div className="footer-copyright">
                    <div className="container">
                        <Link to='/terms' className="grey-text text-lighten-4 left">
                            { Lang('copyright') }
                        </Link>
                        <img alt='flag' onClick={() => this.props.getLanguage('eng')} src={ US } />
                        <img alt='flag' onClick={() => this.props.getLanguage('ua')} src={ UA } />
                        <Link to='/terms' className="grey-text text-lighten-4 right">
                            { Lang('terms') }
                        </Link>
                        <Link to='/privacy-policy' className="grey-text text-lighten-4 right mr-4">
                            { Lang('privacy') }
                        </Link>
                    </div>
                </div>
            </footer>
        )
    }
}

function mapStateToProps(state) {
    //need it to refresh the page
    return {
        selectedLanguage: state.selectedLanguage
    };
}

export default connect(mapStateToProps, actions)(Footer);